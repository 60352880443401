import React, { forwardRef, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import PupilContext from '../../Components/context/PupilContext';
import { routeUrl } from '../../Components/utils/RouteUrl';
import IsCompletedContext from '../../Components/context/IsCompletedContext';
import { NCCReportApi } from '../../constants/apiUrls';
import axios from 'axios';


const NotCompletedIcon = () => {
  return <i class="fa-duotone fa-spinner"></i>
}

const CompletedIcon = () => {
  return <i class="fa-solid fa-check circle-green"></i>
}

const NCCSideBar = (props, ref) => {

  const { activePupilYear, activePupilId, dateOfMeeting } = useContext(PupilContext);
  const { 
    dataIsCompleted,
    pupilVoiceIsCompleted,
    strengthAndNeedIsCompleted,
    meetingIsCompleted,
    targetAndActionIsCompleted,
    setAllStatus,
  } = useContext(IsCompletedContext);

  const { sidebarRef } = ref.current;

  // This Event Listener call on resize
  const resizeEventHandler = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 1179) {
      console.log(screenWidth);
      sidebarRef.current.classList.add("mobile-sidebar")

    }
  }

  // Adding event listener for resize
  useEffect(() => {
    const url = NCCReportApi.getIsCompleteStatusForAllReport({pupilId: activePupilId, dateOfMeeting})
    axios.get(url)
      .then(res => {
        const data = res.data;
        setAllStatus({
          data: data.isCompleteStatus.dataModule,
          pupilVoice: data.isCompleteStatus.voiceModule,
          strengthAndNeeds: data.isCompleteStatus.sendModule,
          meeting: data.isCompleteStatus.meetingModule,
          target: data.isCompleteStatus.targetModule,
        })
        console.log(res, "response from status");
      })
    
  }, [activePupilId, dateOfMeeting])

  useEffect(() => {
    window.addEventListener("resize", resizeEventHandler);
    return () => window.removeEventListener("resize", resizeEventHandler);
  })

  return (
    <div className="lg:flex sm:hidden sidebar" ref={sidebarRef}>
      <div class="pepSidebar lg:w-56">
        <div className="sidebar-list lg:block">
          <div className="basic-info rounded-2xl bg-white">

            <div className="flex mx-2 mb-4">
              <Link
                to={routeUrl.basic_info}
                className="text-sm py-4 px-2 flex justify-between w-full"
              >
                <span>Personal Info</span>
                <i class="flex justify-end mt-1 far fa-chevron-double-left"></i>
              </Link>

            </div>
          </div>
          <div className="other-menu rounded-2xl bg-white">
            <div className="flex">
              <ul className="w-full">  
                <li className="mx-2 my-2 completed">
                  <Link
                    to={routeUrl.ncc_data}
                    className="text-sm py-2 px-2 flex items-center gap-1"
                  >
                    {dataIsCompleted ? <CompletedIcon /> : <NotCompletedIcon /> }
                    {/* <i class="fa-duotone fa-spinner"></i> */}
                    <span className="pl-2"> Data </span>
                  </Link>
                </li>

                <li className="mx-2 my-2 completed">
                  <Link
                    to={routeUrl.ncc_pupil_voice}
                    className="text-sm py-2 px-2 flex items-center gap-1"
                  >
                    {pupilVoiceIsCompleted ? <CompletedIcon /> : <NotCompletedIcon />}
                    {/* <i class="fa-duotone fa-spinner"></i> */}
                    <span className="pl-2"> Pupil Voice </span>
                  </Link>
                </li>

                <li className="mx-2 my-2 completed">
                  <Link
                    to={routeUrl.ncc_strength}
                    className="text-sm py-2 px-2 flex items-center gap-1"
                  >
                    {strengthAndNeedIsCompleted ? <CompletedIcon /> : <NotCompletedIcon />}
                    {/* <i class="fa-solid fa-check circle-green"></i> */}
                    <span className="pl-2"> Strengths and Needs </span>
                  </Link>
                </li>

                <li className="mx-2 my-2 completed">
                  <Link
                    to={routeUrl.ncc_meeting}
                    className="text-sm py-2 px-2 flex items-center gap-1"
                  >
                    {meetingIsCompleted ? <CompletedIcon /> : <NotCompletedIcon /> }
                    {/* <i class="fa-solid fa-check circle-green"></i> */}
                    <span className="pl-2"> Meeting </span>
                  </Link>
                </li>

                <li className="mx-2 my-2 completed">
                  <Link
                    to={routeUrl.ncc_targets}
                    className="text-sm py-2 px-2 flex items-center gap-2 relative"
                  >
                    {targetAndActionIsCompleted ? <CompletedIcon /> : <NotCompletedIcon /> }
                    {/* <i class="fa-solid fa-loader circle-orange"></i> */}
                    <span className="pl-2"> Targets & Actions </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(NCCSideBar);
