/* 
  This Component contain Context for Active Pupil Id, and People for Info 
*/

import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { settings } from "../utils/Settings";

const PupilContext = React.createContext();

export const PupilProvider = (props) => {
  const [activePupilId, setActivePupilId] = useState(
    localStorage.getItem("activePupilId")
      ? localStorage.getItem("activePupilId")
      : null
  );
  const [reloadValue, setreloadValue] = useState(false);
  const [SchoolsId, setSchoolsId] = useState(423685);
  const [handleresponse, sethandleresponse] = useState(false);
  const [ExtractionValues, setExtractionValues] = useState("");
  const [updatevalue, seupdatevalue] = useState(false);
  const [SelectedPupilex, setSelectedPupilex] = useState(false);
  const [newdate, setnewdate] = useState("");
  const [selectedClient,setselectedClient] = useState('')
  //const [summaryvalue,setsummaryvalue] = useState('')
  const [cictype, setcictype] = useState("");
  const [KS5,setKS5] = useState('')
  const [sortingValue, setSortingValue] = useState("");
  const [searchesValue,setsearchesValue] = useState('')
  const [deletevalues,setdeletevalues] = useState(false)
  const [Urnstts,setUrnstts] = useState(null)
  const [attnvalue,setattnvalue] = useState('')
  const [showKs5True, setShowKs5True] = useState(false);
  const [callre,setcallre] = useState(false)
  const [callAgain,setcallAgain] = useState(false)

  let initialYear = null;
  if (localStorage.getItem("activePupilYear")) {
    initialYear = localStorage.getItem("activePupilYear");
  } else {
    initialYear = null;
  }
  const [activePupilYear, setActivePupilIdYear] = useState(
    localStorage.getItem("activePupilYear") &&
      localStorage.getItem("activePupilYear") !== "null"
      ? localStorage.getItem("activePupilYear")
      : null
    // initialYear
  );
  const [pupilInfo, setPupilInfo] = useState({});
  const [ClientValue, setClientValue] = useState("");
  const [SubSchoolValue, setSubSchoolValue] = useState("");
  const [valuepupil, setvaluepupil] = useState("");
  const [dateOfMeeting, setDateOfMeeting] = useState(
    localStorage.getItem("activePupilMeetingDate")
      ? localStorage.getItem("activePupilMeetingDate")
      : {}
  );

  const setActivePupilIdHandler = (value, year, meetingDateProp) => {
    localStorage.setItem("activePupilMeetingDate", meetingDateProp);
    setActivePupilIdYear(year);
    setActivePupilId(value);
    setDateOfMeeting(meetingDateProp);
  };

  const authorizationKey = localStorage.getItem("authorizationKey");
  const userId = localStorage.getItem("userId");
  const userName = localStorage.getItem("userName");

  // When activePupilId changes this useEffect calls and get basic details
  useEffect(() => {
    const source = axios.CancelToken.source();
    var url1
    console.log(attnvalue,'///////////')
    const newvalue = 'Y13'
    // if (newvalue === 'Y13'){
    //   url1 =  `${settings.API_HOST}/rest/fetchPupilks5Attendance?pupilId=${activePupilId}`
    //   setShowKs5True(true)
    // }
  //  else{
      
      
      url1 = `${settings.API_HOST}/rest/fetchPupilBasicDetails?pupilId=${activePupilId}`;
   // }
    
    console.log(url1, " ????????????????????????");
   var testData
    axios
      .get(url1)
      .then((res) => {
        if (res.data.pupilBasicDetails !== null) {
          console.log(res);
          // Setting Pupil Id to Local Storage
           testData = res?.data?.pupilBasicDetails 
          let Term = 'Autumn'
          //res.data.term;
          const activePupilMeetingDate = localStorage.getItem(
            "activePupilMeetingDate"
          );
          localStorage.setItem("termOfPep", testData.termOfPep);
          localStorage.setItem("term", Term);
          if (activePupilMeetingDate && activePupilMeetingDate !== "No PEP") {
            setPupilInfo({
              ...testData,
              dateofmeeting: activePupilMeetingDate,
            });
          } else {
            setPupilInfo(testData);
            setDateOfMeeting(testData.dateofmeeting);
          }

          // localStorage.setItem("activePupilMeetingDate", dateOfMeeting);
          localStorage.setItem("activePupilId", activePupilId);
          localStorage.setItem("activePupilYear", activePupilYear);

          toast.success("Pupil selected successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            draggable: true,
          });
        }
      })
      .catch((err) => {
        toast.warn("Something went wrong while selecting student Context", {
          position: "top-right",
          autoClose: 3000,
          closeOnClick: true,
          pauseOnHover: false,
        });
      });
    return () => source.cancel();
  }, [activePupilId, reloadValue,SelectedPupilex]);

  useEffect(() => {
    localStorage.setItem("activePupilMeetingDate", dateOfMeeting);
  }, [dateOfMeeting]);

  return (
    <PupilContext.Provider
      value={{
        activePupilId,
        setActivePupilIdHandler,
        setActivePupilId,
        setClientValue,
        ClientValue,
        setSubSchoolValue,
        pupilInfo,
        activePupilYear,
        setPupilInfo,
        SubSchoolValue,
        dateOfMeeting,
        setDateOfMeeting,
        reloadValue,
        setreloadValue,
        setSchoolsId,
        SchoolsId,
        sethandleresponse,
        handleresponse,
        setExtractionValues,
        ExtractionValues,
        seupdatevalue,
        updatevalue,
        setSelectedPupilex,
        SelectedPupilex,
        setnewdate,
        newdate,
        setSortingValue,
        sortingValue,
        setcictype,
        cictype,
        setvaluepupil,
        valuepupil,
        setsearchesValue,
        searchesValue,
        setselectedClient,
        selectedClient,
        setdeletevalues,
        deletevalues,
        setKS5,
        KS5,
        setUrnstts,
        Urnstts,
        attnvalue,
        setattnvalue,
        setShowKs5True,
        showKs5True,
        callre,
        setcallre,
        callAgain,
        setcallAgain
      }}
    >
      {props.children}
    </PupilContext.Provider>
  );
};

export default PupilContext;
