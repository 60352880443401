import axios from 'axios';
import React, { useContext } from 'react'
import PupilContext from '../../../Components/context/PupilContext'
import { getYearGroup } from '../../../Components/utils/utilityFunctions';
import { NCCReportApi } from '../../../constants/apiUrls';
import PepSecondaryHeader from '../../Pep/PepSecondaryHeader';
import NCCSecondaryHeader from '../NCCSecondaryHeader'
import NccPupilVoiceComponent from './components/NCCPupilVoice';
import NCCPupilVoicePost16 from './components/NCCPupilVoicePost16';

const PupilVoice = () => {

  const { pupilInfo } = useContext(PupilContext);

  const componentToRender = {
    Component: null,
    componentTitle: null,
    reportName: ""
  }

  const yearGroup = getYearGroup(pupilInfo.classYear);

  switch (yearGroup) {
    case "eyfs":
      componentToRender.Component = <NccPupilVoiceComponent />;
      componentToRender.componentTitle = "EYFS";
      componentToRender.reportName = "nccPupilvoice"; 
      break;

    case "primary":
      componentToRender.Component = <NccPupilVoiceComponent />;
      componentToRender.componentTitle = "Primary";
        componentToRender.reportName = "nccPupilvoice"; 
      break;

    case "secondary":
      componentToRender.Component = <NccPupilVoiceComponent />;
      componentToRender.componentTitle = "Secondary";
      componentToRender.reportName = "nccPupilvoice"; 
      break;

    case "post16":
      componentToRender.Component = <NCCPupilVoicePost16 />;
      componentToRender.componentTitle = "POST-16";
      componentToRender.reportName = "nccPost16Pupilvoice"; 
      break;

  }

  // const exportPdf = () => {
  //   const a = document.createElement("a");
  //   const url = NCCReportApi.exportPdfReports({
  //     reportName: componentToRender.reportName,
  //     pupilId: pupilInfo.pupilId,
  //     schoolId: pupilInfo.schoolId,
  //     classYear: pupilInfo.classYear,
  //     termofpep: pupilInfo.termOfPep,
  //     pupilsName: pupilInfo.fullname,
  //     dateofmeeting: pupilInfo.dateofmeeting,
  //   });
  //   axios.get(url, { responseType: "blob" })
  //     .then((res) => {
  //       let objectUrl = window.URL.createObjectURL(res.data);
  //       a.href = objectUrl;
  //       a.download = componentToRender.reportName;
  //       a.click();
  //       window.URL.revokeObjectURL(objectUrl);
  //       a.remove();
  //     })
  //     .catch(err => {
  //       const error = err;
  //       console.log(err);
  //       console.log(err);
  //     })
  // }

  return (
    <>
      {/* <PepSecondaryHeader
        heading={`NCC PupilVoice ${componentToRender.componentTitle}`}
        buttons={[
          <button className="blue-button complete-button  text-sm">Complete</button>,
          <div class="export-btn">
            <span className="blue-button px-5 py-2  text-sm">Export</span>
            <ul>
              <li>
                <button onClick={exportPdf}>Full Pep</button>
              </li>
              <li>
                <button onClick={exportPdf}>PDF</button>
              </li>
            </ul>
          </div>
        ]}
      /> */}
      {componentToRender.Component !== null && componentToRender.Component}
    </>
  )
}

export default PupilVoice
