import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect, useContext, useReducer } from 'react';
import PupilContext from '../../../../Components/context/PupilContext';
import { settings } from '../../../../Components/utils/Settings';
import PepSecondaryHeader from '../../PepSecondaryHeader';
import cincpReducer from '../../../../reducers/cincpReducer';
import { setPepCincpPrimary } from '../../../../constants/reducerActions';
import { Form, Formik } from 'formik';
import CINCPTextArea from './CINCPTextArea';
import { pepCinCpReportApi } from "../../../../constants/apiUrls"
import CINCPInputField from './CINCPInputField';
import CINCPHoc from './CINCPHoc';


const initialState = {
  keyStage1Phonics: "",
  keyStage1Writing: "",
  keyStage1Reading: "",
  keyStage1Maths: "",
  keyStage2Spag: "",
  keyStage2Writing: "",
  keyStage2Reading: "",
  keyStage2Maths: "",
  keyStage4English: "",
  keyStage4Maths: "",
  comment: "",
  attendanceEndOfAutumn: "",
  attendanceEndOfSpring: "",
  attendanceEndOfSummer: "",
  readingEndOfAutumn: "",
  readingEndOfSpring: "",
  readingEndOfSummer: "",
  writingEndOfAutumn: "",
  writingEndOfSpring: "",
  writingEndOfSummer: "",
  mathsEndOfAutumn: "",
  mathsEndOfSpring: "",
  mathsEndOfSummer: "",
  spagEndOfAutumn: "",
  spagEndOfSpring: "",
  spagEndOfSummer: "",
  commentsDevelopmentEndOfAutumn: "",
  commentsDevelopmentEndOfSpring: "",
  commentsDevelopmentEndOfSummer: "",
  educationNeedThisChild: "",
  barrierToMakingGoodProgress: "",
  spendingPpgChild: "",
  helpOvercomeTheBarriers: "",
  childIsNotInSchool: "",
  successLookLike: "",
}

const PepCinCPPrimary = ({ textFieldHandler, saveField, historyModel, openHistoryPopup }) => {

  const { activePupilId } = useContext(PupilContext);

  const [cipCp, dispatch] = useReducer(cincpReducer, initialState)

  useEffect(() => {
    const fetchUrl = pepCinCpReportApi.getCinCpReport(activePupilId);
    activePupilId && axios(fetchUrl)

      .then(res => {
        console.log(res.data, "Esol data");
        // setCinCpData(res.data.pepEyfsCinCp);
        dispatch({ type: setPepCincpPrimary, payload: { pepEyfsCinCp: res.data.pepEyfsCinCp } })
        //setEsolUploads(res.data.pepEsolSchoolDocs);

      });

  }, [activePupilId]);

  const initialFormState = {
    keyStage1Phonics: cipCp.keyStage1Phonics,
    keyStage1Writing: cipCp.keyStage1Writing,
    keyStage1Reading: cipCp.keyStage1Reading,
    keyStage1Maths: cipCp.keyStage1Maths,
    keyStage2Spag: cipCp.keyStage2Spag,
    keyStage2Writing: cipCp.keyStage2Writing,
    keyStage2Reading: cipCp.keyStage2Reading,
    keyStage2Maths: cipCp.keyStage2Maths,
    keyStage4English: cipCp.keyStage4English,
    keyStage4Maths: cipCp.keyStage4Maths,
    comment: cipCp.comment,
    attendanceEndOfAutumn: cipCp.attendanceEndOfAutumn,
    attendanceEndOfSpring: cipCp.attendanceEndOfSpring,
    attendanceEndOfSummer: cipCp.attendanceEndOfSummer,
    readingEndOfAutumn: cipCp.readingEndOfAutumn,
    readingEndOfSpring: cipCp.readingEndOfSpring,
    readingEndOfSummer: cipCp.readingEndOfSummer,
    writingEndOfAutumn: cipCp.writingEndOfAutumn,
    writingEndOfSpring: cipCp.writingEndOfSpring,
    writingEndOfSummer: cipCp.writingEndOfSummer,
    mathsEndOfAutumn: cipCp.mathsEndOfAutumn,
    mathsEndOfSpring: cipCp.mathsEndOfSpring,
    mathsEndOfSummer: cipCp.mathsEndOfSummer,
    spagEndOfAutumn: cipCp.spagEndOfAutumn,
    spagEndOfSpring: cipCp.spagEndOfSpring,
    spagEndOfSummer: cipCp.spagEndOfSummer,
    commentsDevelopmentEndOfAutumn: cipCp.commentsDevelopmentEndOfAutumn,
    commentsDevelopmentEndOfSpring: cipCp.commentsDevelopmentEndOfSpring,
    commentsDevelopmentEndOfSummer: cipCp.commentsDevelopmentEndOfSummer,
    educationNeedThisChild: cipCp.educationNeedThisChild,
    barrierToMakingGoodProgress: cipCp.barrierToMakingGoodProgress,
    spendingPpgChild: cipCp.spendingPpgChild,
    helpOvercomeTheBarriers: cipCp.helpOvercomeTheBarriers,
    childIsNotInSchool: cipCp.childIsNotInSchool,
    successLookLike: cipCp.successLookLike,
  }

  return (
    <>
      <useToastContainer />
      <PepSecondaryHeader
        subHeading=""
        heading="CIN / CP / PLAC Primary - Education Info"
        buttons={[
          <button className="blue-button px-5 py-2  text-sm">Complete</button>,
        ]}
      />
      <Formik
        initialValues={initialFormState}
        enableReinitialize
      >
        <Form>
          <div className="secondary-section w-full">
            <div className="rounded-3xl bg-white p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="blue rounded-3xl w-full px-4 py-2">Prior Attainment</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table class="table-fixed text-left w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32">EYFS</th>
                        <th className="d-blue w-32">GLD achieved?</th>
                        <th className="purple w-64"></th>
                        <th className="orange w-32">Phonics Score</th>
                        <th className="d-orange w-64"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">Key stage 1</td>
                        <td>Phonics</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName={"keyStage1Phonics"}
                          />
                          {/* {cipCp.keyStage1Phonics} */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Writing"
                          />
                        </td>
                        {/* <td>{cipCp.keyStage1Writing}</td> */}
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Reading"
                          />
                        </td>
                        {/* <td>{cipCp.keyStage1Reading}</td> */}
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Maths"
                          />
                        </td>
                        {/* <td>{cipCp.keyStage1Maths}</td> */}
                      </tr>
                      <tr>
                        <td rowspan="2">Key stage 2</td>
                        <td>SPaG</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Spag"
                          />
                          {/* {cipCp.keyStage2Spag} */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Writing"
                          />
                          {/* {cipCp.keyStage2Writing} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Reading"
                          />
                          {/* {cipCp.keyStage2Reading} */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Maths"
                          />
                          {/* {cipCp.keyStage2Maths} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Key stage 4</td>
                        <td>English</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4English"
                          />
                          {/* {cipCp.keyStage4English} */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4Maths"
                          />
                          {/* {cipCp.keyStage4Maths} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td colspan="4">
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="comment"
                          />
                          {/* {cipCp.comment} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            
            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="pink rounded-3xl w-full px-4 py-2">Latest Attendance and Attainment (Primary)</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table class="table-fixed text-center w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32"></th>
                        <th className="d-blue w-4/12">End of Autumn 2021</th>
                        <th className="purple w-4/12">End of Spring 2022</th>
                        <th className="orange w-4/12">End of Summer 2022</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Attendance</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfAutumn"
                          />
                          {/* {cipCp.attendanceEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSpring"
                          />
                          {/* {cipCp.attendanceEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSummer"
                          />
                          {/* {cipCp.attendanceEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="readingEndOfAutumn"
                          />
                          {/* {cipCp.readingEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="readingEndOfSpring"
                          />
                          {/* {cipCp.readingEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="readingEndOfSummer"
                          />
                          {/* {cipCp.readingEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="writingEndOfAutumn"
                          />
                          {/* {cipCp.writingEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="writingEndOfSpring"
                          />
                          {/* {cipCp.writingEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="writingEndOfSummer"
                          />
                          {/* {cipCp.writingEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfAutumn"
                          />
                          {/* {cipCp.mathsEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfSpring"
                          />
                          {/* {cipCp.mathsEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfSummer"
                          />
                          {/* {cipCp.mathsEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>SPAG</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="spagEndOfAutumn"
                          />
                          {/* {cipCp.spagEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="spagEndOfSpring"
                          />
                          {/* {cipCp.spagEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="spagEndOfSummer"
                          />
                          {/* {cipCp.spagEndOfSummer} */}
                        </td>
                      </tr>

                      <tr>
                        <td>Comment</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfAutumn"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSpring"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSummer"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfSummer} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the main educational needs of this child? 
                    <i
                      class="fas fa-folder-open cursor-pointer"
                      onClick={() => openHistoryPopup("education_need_this_child")}
                    ></i>
                  </p>
                  {/* <p className="inline-block">What are the main educational needs of this child? <i class="fas fa-folder-open"></i></p> */}
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"educationNeedThisChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the barriers to making good progress? 
                    <i 
                      class="fas fa-folder-open cursor-pointer" 
                      onClick={() => openHistoryPopup("barrier_to_making_good_progress")}
                    ></i>
                  </p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"barrierToMakingGoodProgress"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />

                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">How is the school spending PPG for this child? 
                    <i
                      class="fas fa-folder-open cursor-pointer"
                      onClick={() => openHistoryPopup("spending_ppg_child")}
                    ></i>
                  </p>
                  {/* <p className="inline-block">How is the school spending PPG for this child? <i class="fas fa-folder-open"></i></p> */}
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"spendingPpgChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />

                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What additional support might help overcome the barriers? 
                    <i
                      class="fas fa-folder-open cursor-pointer"
                      onClick={() => openHistoryPopup("help_overcome_the_barriers")}
                    ></i>
                  </p>
                  {/* <p className="inline-block">What additional support might help overcome the barriers? <i class="fas fa-folder-open"></i></p> */}
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"helpOvercomeTheBarriers"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />

                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">If this child is NOT in school 
                    <i
                      class="fas fa-folder-open cursor-pointer"
                      onClick={() => openHistoryPopup("child_is_not_in_school")}
                    ></i>
                  </p>
                  {/* <p className="inline-block">If this child is NOT in school <i class="fas fa-folder-open"></i></p> */}
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"childIsNotInSchool"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />

                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What would success look like? 
                    <i
                      class="fas fa-folder-open cursor-pointer"
                      onClick={() => openHistoryPopup("success_look_like")}
                    ></i>
                  </p>
                  {/* <p className="inline-block">What would success look like? <i class="fas fa-folder-open"></i></p> */}
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder={""}
                      fieldName={"successLookLike"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />

                  </div>
                </div>
              </div>
            </div>

            {historyModel}
          </div>
        </Form>
      </Formik>
    </>
  )
}
export default CINCPHoc(PepCinCPPrimary);