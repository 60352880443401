import axios from 'axios'
import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useContext, useReducer } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import PupilContext from '../../../../Components/context/PupilContext'
import CompleteButton from '../../../../Components/NCC/CompleteButton'
import SecondaryKs3SubjectRow from '../../../../Components/NCC/Data/SecondaryKs3SubjectRow'
import DateField from '../../../../Components/NCC/DateField'
import InputField from '../../../../Components/NCC/InputField'
import NCCDataEyfsSkeleton from '../../../../Components/NCC/NCCDataEyfsSkeleton'
import NccDataHoc from '../../../../Components/NCC/NccDataHoc'
import RadioField from '../../../../Components/NCC/RadioField'
import SelectField from '../../../../Components/NCC/SelectField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import { checkForForTrimmedEmptyValue, formValidationWrapper, getPrototypeName, getYearGroup, getArrayIndexFromString, handleEmptyValuesForSaving, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import { NCCReportApi } from '../../../../constants/apiUrls'
import { setLoading, setNccSecondaryData, setUploadedSchoolReports } from '../../../../constants/reducerActions'
import nccDataSecondaryReducer from '../../../../reducers/NCCData/nccDataSecondaryReducer'
import DataDateField from '../../../Pep/PepData/components/DataDateField'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'
import UploadModal from '../../../Pep/PupilVoice/components/UploadModal'



const initialState2 = {
  presentPer: null,
  exclusionCount: null,
  dataSecondary: null,
  pupilUniqueInfo: null,
  schoolUploadedFiles: null,
  previousdataSecondary: null,
  secondaryDataOptionsList: null,
  isLoading: false,
}

const progressOptionList = [
  <option value=""></option>,
  <option value="Below">Below</option>,
  <option value="Expected">Expected</option>,
  <option value="Exceeding">Exceeding</option>,
]

const primaryNeedOptionList = [
  <option value=""></option>,
  <option value="Specific Learning Difficulty">Specific Learning Difficulty</option>,
  <option value="Moderate Learning Difficulty">Moderate Learning Difficulty</option>,
  <option value="Severe Learning Difficulty">Severe Learning Difficulty</option>,
  <option value="Profound &amp; Multiple Learning Difficulty">Profound &amp; Multiple Learning Difficulty</option>,
  <option value="Social, Emotional and Mental Health">Social, Emotional and Mental Health</option>,
  <option value="Speech, Language and Communications Needs">Speech, Language and Communications Needs</option>,
  <option value="Hearing Impairment">Hearing Impairment</option>,
  <option value="Visual Impairment">Visual Impairment</option>,
  <option value="Multi-Sensory Impairment">Multi-Sensory Impairment</option>,
  <option value="Physical Disability">Physical Disability</option>,
  <option value="Autistic Spectrum Disorder">Autistic Spectrum Disorder</option>,
  <option value="Other Difficulty/Disability">Other Difficulty/Disability</option>,
]

const NCCDataSecondary = ({
  attendanceButtonHandler,
  exclusionsButtonHandler,
  // saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  downloadUploadedFile,
  deleteUploadedFile,
  exportPdf,
}) => {
  const pdfReportName = "nccDataSecondary";
  const fullPepPdfReportName = "NccFullPep";

  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");

  const { dateOfMeeting, pupilInfo, activePupilId } = useContext(PupilContext);

  // Is Completed context for setting sidebar
  const { setIsCompleted } = useContext(IsCompletedContext);

  const [state, dispatch] = useReducer(nccDataSecondaryReducer, initialState2)

  const [isOpen, setIsOpen] = useState(false);

  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);

  let secondaryOptions = [<option value=""></option>,]
  state.secondaryDataOptionsList && state.secondaryDataOptionsList.forEach(item => secondaryOptions.push(<option value={item}>{item}</option>))

  useEffect(() => {
    const source = axios.CancelToken.source();
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });
      const url = NCCReportApi.getSecondaryData({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url, { cancelToken: source.token })
        .then((res) => {
          dispatch({ type: setNccSecondaryData, payload: res.data })
          console.log(res.data, "response from ncc data Secondary");
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    
    return () => {
      source.cancel();
    }
  }, [dateOfMeeting])

  

  const saveForm = ({ setIsLoading, values, saveCallback = () => {} }) => {
    const postUrl = NCCReportApi.saveSecondaryData;
    const formData = new FormData();
    let objectName = "";
    if (getYearGroup(pupilInfo.classYear) === "eyfs") { objectName = "dataEyfs" }
    if (getYearGroup(pupilInfo.classYear) === "primary") { objectName = "dataPrimary" }
    if (getYearGroup(pupilInfo.classYear) === "secondary") { objectName = "dataSecondary" }
    if (getYearGroup(pupilInfo.classYear) === "post16") { objectName = "dataPost16" }

    Object.entries(values[objectName]).forEach(([key, value]) => {
      const valueType = getPrototypeName(value);
      if (valueType === "Object") {
        Object.entries(value).forEach(([key2, value2]) => {
          formData.append(`${objectName}.${key}.${key2}`, value2 ? value2 : "");
        })
      } else if (valueType !== "Object" && valueType !== "Array") {
        formData.append(`${objectName}.${key}`, value ? value : "")
      }
      console.log(typeof value, key);
    })
    
    const pupilUnique = values.pupilUniqueInfo;

    pupilUnique.subjectList.forEach((subject, index) => {
      Object.entries(subject).forEach(([key, value]) => {
        formData.append(`pupilUniqueInfo.subjectList[${index}].${key}`, handleEmptyValuesForSaving(value));
      })
    })

    if (!pupilUnique.subjectList || pupilUnique.subjectList?.length <= 0) {
      formData.append(`pupilUniqueInfo.subjectList`, null);
    }

    formData.append(
      "pupilUniqueInfo.goodLevOfDevAchiev",
       pupilUnique?.goodLevOfDevAchiev ? pupilUnique.goodLevOfDevAchiev : "")
    formData.append(
      "pupilUniqueInfo.keyStage1PriorAttainment",
       pupilUnique?.keyStage1PriorAttainment ? pupilUnique.keyStage1PriorAttainment : "")
    formData.append(
      "pupilUniqueInfo.phonicKeyStage1Score",
       pupilUnique?.phonicKeyStage1Score ? pupilUnique.phonicKeyStage1Score : "")
    formData.append(
      "pupilUniqueInfo.readingKeyStage1Score",
       pupilUnique?.readingKeyStage1Score ? pupilUnique.readingKeyStage1Score : "")
    formData.append(
      "pupilUniqueInfo.writingKeyStage1Score",
       pupilUnique?.writingKeyStage1Score ? pupilUnique.writingKeyStage1Score : "")
    formData.append(
      "pupilUniqueInfo.mathKeyStage1Score",
       pupilUnique?.mathKeyStage1Score ? pupilUnique.mathKeyStage1Score : "")
    formData.append(
      "pupilUniqueInfo.keyStage2PriorAttainment",
       pupilUnique?.keyStage2PriorAttainment ? pupilUnique.keyStage2PriorAttainment : "")
    formData.append(
      "pupilUniqueInfo.ks2ScoreSPAG",
       pupilUnique?.ks2ScoreSPAG ? pupilUnique.ks2ScoreSPAG : "")
    formData.append(
      "pupilUniqueInfo.ks2ScoreReading",
       pupilUnique?.ks2ScoreReading ? pupilUnique.ks2ScoreReading : "")
    formData.append(
      "pupilUniqueInfo.ks2ScoreWriting",
       pupilUnique?.ks2ScoreWriting ? pupilUnique.ks2ScoreWriting : "")
    formData.append(
      "pupilUniqueInfo.ks2ScoreMaths",
       pupilUnique?.ks2ScoreMaths ? pupilUnique.ks2ScoreMaths : "")


    axios.post(postUrl, formData)
      .then(res => {
        console.log(res.data, "saving response form eyfs data");
        setIsLoading(false);
        saveCallback();
      })
      .catch(err => setIsLoading(false))
    setIsLoading(true);
    
  }

  const uploadedDocumentListHandler = (data, form) => {
    form.setFieldValue("schoolUploadedFiles", data);
    // dispatch({ type: setUploadedSchoolReports, payload: { schoolReports: data } })
  }

  const initialFormState = {
    presentPer: state.presentPer,
    exclusionCount: state.exclusionCount,
    dataSecondary: state.dataSecondary,
    pupilUniqueInfo: state.pupilUniqueInfo,
    previousdataSecondary: state.previousdataSecondary,
    schoolUploadedFiles: state.schoolUploadedFiles,
  }

  const isCompletedCallback = () => {
    showReportCompleteSuccess("Data Secondary Completed");
  }

  const successCallback = (values) => {
    setIsCompleted("dataIsCompleted", true);
    values.dataSecondary.isCompleted = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.dataSecondary.commonData.youngPersonHaveSEND)) {
      error = "'Does this young person have SEND?' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.presentPer)) {
      error = "Attendance is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.goodLevOfDevAchiev)) {
      error = "'Good level of development currently achived?' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataSecondary.makingGoodProgress)) {
      error = "'Key Stage 1 Reading' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.readingKeyStage1Score)) {
      error = "'Key Stage 1 Reading' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.writingKeyStage1Score)) {
      error = "'Key Stage 1 Writing' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.mathKeyStage1Score)) {
      error = "'Key Stage 1 Maths' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.ks2ScoreReading)) {
      error = "'Key Stage 2 Reading' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.ks2ScoreWriting)) {
      error = "'Key Stage 2 Writing' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.pupilUniqueInfo.ks2ScoreMaths)) {
      error = "'Key Stage 2 Maths' is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataSecondary.engCurrentGrade)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.endEndOfYearTarget)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.engY11Target)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.engY11Estimate)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.engProgress)) {
      error = "Key Stage 3/4 is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsCurrentGrade)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsEndOfYearTarget)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsY11Target)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsY11Estimate)) {
      error = "Key Stage 3/4 is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsProgress)) {
      error = "Key Stage 3/4 is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataSecondary.engTargetThisYear)) {
      error = "'Is the student on track to reach their English target this year?' is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.mathsTargetThisYear)) {
      error = "'Is the student on track to reach their Maths target this year? *' is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.makingGoodProgress)) {
      error = "'From your assessments, is the child/young person making good progress?' is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataSecondary.sdqDate)) {
      error = "'SDQ Date' is required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    attributeName: "dataIsCompleted",
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  const ks3CustomSubjectOnChangeHandler = ({
    fieldName,
    fieldValue,
    setIsLoading,
    saveForm,
    setFieldValue,
    values
  }) => {
    setFieldValue(fieldName, fieldValue);
    const updatedValues = {...values};
    const splittedFieldName = fieldName.split(".");
    const secondSplittedValue = splittedFieldName[1].split("[")[0];
    const arrayIndex = getArrayIndexFromString(splittedFieldName[1]);
    updatedValues[splittedFieldName[0]][secondSplittedValue][arrayIndex][splittedFieldName[2]] = fieldValue;

    saveForm({
      setIsLoading,
      values: updatedValues,

    })
  }

  const addKs3Subject = (form, push) => {
    let rowNumber = 0;
    const subjectList = form.values.pupilUniqueInfo.subjectList;
    
    if(subjectList && subjectList.length > 0) {
      const lastIndex = subjectList.length - 1;
      const lastRowNumber = parseInt(subjectList[lastIndex].rowNumber);
      const nextRowNumber = lastRowNumber + 1;
      rowNumber = nextRowNumber;
    }

    const subject = {
      rowNumber,
      curGrade: "",
      endYearTarget: "",
      expected: "",
      id: null,
      preGrade: "",
      progress: "",
      pupilId: activePupilId,
      subName: "",
      target: "",
    }

    push(subject);
  }

  const deleteKs3Subject = ({
    form,
    index,
    setIsLoading,
    remove
  }) => {
    // Created copy of form values
    const updatedValues = { ...form.values };

    // Updating form values manually for autosaving
    const newList = updatedValues.pupilUniqueInfo.subjectList.filter((item, idx) => {
      if (idx !== index) {
        return true;
      } else {
        return false;
      }
    });
    // const afterSaveCallback = () => remove(index);
    const afterSaveCallback = () => form.setFieldValue("pupilUniqueInfo.subjectList", newList);
    updatedValues.pupilUniqueInfo.subjectList = newList;

    // Now calling the saving API
    saveForm({
      setIsLoading,
      values: updatedValues,
      saveCallback: afterSaveCallback,
    })
  }

  return (

    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC PEP - Data Secondary`}
          buttons={[
            <CompleteButton
              fieldName={"dataSecondary.isCompleted"}
              completeButtonLoader={completeButtonLoader}
            />,
            <div class="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NCCDataEyfsSkeleton /> :
            <>
              {/* First part */}
              <div className="rounded-3xl bg-white lg:w-max md:pr-8 overflow-hidden">
                <div className="flex flex-col gap-2 md:flex-row md:gap-3 md:items-center">
                  <label htmlFor="" className='p-6 blue md:w-96'>Does this young person have SEND? <span className='required'>*</span></label>
                  <div className='pl-4 pt-2 pb-4 md:pl-0 md:pb-0'>
                    <RadioField
                      fieldName="dataSecondary.commonData.youngPersonHaveSEND"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />
                  </div>
                </div>

                <div className="divide-solid divide-class ml-2 w-full"></div>

                <div className="flex flex-col gap-2 md:flex-row md:gap-3">
                  <label htmlFor="" className='p-6 pb-12 blue md:w-96'>If yes, how are they being supported? <span className='required'>*</span></label>
                  <div className='flex flex-col gap-3 pl-4 pb-4 md:pl-0 md:pb-0'>
                    <div className="flex items-center gap-3 md:pt-6">
                      <label htmlFor="" className='w-28'>SEN Support</label>
                      <Field name="dataSecondary.commonData.youngPersonHaveSEND">
                        {
                          ({form, field}) => {
                            
                            const sendValue = form.values?.dataSecondary?.commonData?.youngPersonHaveSEND;
                            return (
                              <RadioField
                                fieldName="dataSecondary.commonData.SENSupport"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                    </div>
                    <div className="flex items-center gap-3">
                      <label htmlFor="" className='w-28'>EHCP</label>
                      <Field name="dataSecondary.commonData.youngPersonHaveSEND">
                        {
                          ({ form, field }) => {
                            const sendValue = form.values.dataSecondary?.commonData?.youngPersonHaveSEND;
                            return (
                              <RadioField
                                fieldName="dataSecondary.commonData.EHCP"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}

                              />
                            )
                          }
                        }
                      </Field>
                      {/* <RadioField
                        fieldName="dataSecondary.commonData.EHCP"
                        onChangeHandler={onChangeHandler}
                        saveForm={saveForm}
                        radioOptionsList={[
                          { innerValue: "Yes", outerValue: "Yes" },
                          { innerValue: "No", outerValue: "No" },
                        ]}
                      /> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Attendance</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex gap-2 items-center">
                      <label htmlFor="">Attendance %: <span className="required">*</span> </label>
                      <InputField
                        fieldName="presentPer"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button
                        type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={attendanceButtonHandler}
                      >Enter Attendance</button>

                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Attendance</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If below 95%, what actions are being taken or planned?</p>
                    <TextAreaField
                      fieldName="dataSecondary.commonData.actionsBeingTaken"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Exclusions</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex items-center gap-3">
                      <label htmlFor="">No of seassions: </label>
                      <InputField
                        fieldName="exclusionCount"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={exclusionsButtonHandler}
                      >Enter Exculsions</button>
                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Exclusions</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <TextAreaField
                      fieldName="dataSecondary.commonData.actionsAreInPlace"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
              </div>

              {/* thirda part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <h2 className='font-medium'>If YES please answer the following:</h2>
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label htmlFor="">If Yes for ECHP, what is the primary identified need?</label>
                  <SelectField
                    fieldName={"dataSecondary.commonData.primaryIdentifiedNeed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={primaryNeedOptionList}
                  />
                </div>
              </div>

              {/* Forth part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 gap-5">
                <div className="flex flex-col gap-2 md:gap-4 md:flex-row md:items-center">
                  <label className="pr-4">Date Completed</label>
                  <DateField
                    dateChangeHandler={dateChangeHandler}
                    fieldName="dataSecondary.stringCreatedDate"
                    saveForm={saveForm}
                  />
                </div>
                <div className="flex flex-col gap-2 mt-4 md:gap-4 md:flex-row md:items-center">
                  <h3 className="pink rounded-3xl w-full px-4 py-2">Early Years Foundation Stage</h3>
                </div>
                <div className="flex flex-col gap-2 mt-4 md:gap-4 md:flex-row md:items-center">
                  <label>Good level of development currently achived? <span className="required">*</span></label>
                  <RadioField
                    fieldName="pupilUniqueInfo.goodLevOfDevAchiev"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No" },
                    ]}
                  />
                </div>
                <div className="flex mt-4 blue rounded-3xl px-4 py-2 gap-4">
                  <h3 className="">Key Stage 1</h3>
                  <RadioField
                    fieldName="pupilUniqueInfo.keyStage1PriorAttainment"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No" },
                    ]}
                  />
                </div>
                <div className="rounded-3xl bg-white w-full py-4 gap-5">
                  <div className="table-box overflow-x-auto">
                    <table class="table-auto w-full">
                      <tbody>
                        <tr>
                          <td className="blue">Phonics</td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.phonicKeyStage1Score"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="d-blue">Reading<span className="required">*</span> </td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.readingKeyStage1Score"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="purple">Writing<span className="required">*</span> </td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.writingKeyStage1Score"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="orange">Maths<span className="required">*</span></td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.mathKeyStage1Score"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex mt-4 blue rounded-3xl px-4 py-2 gap-4">
                  <h3 className="">Key Stage 2 Scale Scores</h3>
                  <RadioField
                    fieldName="pupilUniqueInfo.keyStage2PriorAttainment"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No" },
                    ]}
                  />
                </div>
                <div className="rounded-3xl bg-white w-full py-4 gap-5">
                  <div className="table-box overflow-x-auto">
                    <table class="table-auto w-full">
                      <tbody>
                        <tr>
                          <td className="blue">SPaG</td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.ks2ScoreSPAG"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="d-blue">Reading<span className="required">*</span> </td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.ks2ScoreReading"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="purple">Writing<span className="required">*</span> </td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.ks2ScoreWriting"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                          <td className="orange">Maths<span className="required">*</span></td>
                          <td>
                            <TextAreaField
                              fieldName="pupilUniqueInfo.ks2ScoreMaths"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={2}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="flex w-full pt-4">
                  <h3 className="blue rounded-3xl w-full px-4 py-2">Key Stage 3/4</h3>
                </div>
                <div className="grid table-box w-full py-4">
                  <div className="table-outer finanace-table overflow-x-auto">
                    <table class="table-auto py-2">
                      <thead>
                        <tr>
                          <th className="blue"></th>
                          <th className="d-blue">Previous Grade</th>
                          <th className="purple">Current Grade <span className="required">*</span></th>
                          <th className="orange">End of Year Target <span className="required">*</span></th>
                          <th className="d-orange">Y11 Target <span className="required">*</span></th>
                          <th className="d-pink">Y11 Predicted <span className="required">*</span></th>
                          <th className="pink">Progress <span className="required">*</span></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="blue">English</td>
                          <td>
                            <InputField
                              fieldName="previousdataSecondary.engCurrentGrade"
                              saveForm={saveForm}
                              onChangeHandler={numberOnChangeHandler}
                              rest={{
                                readOnly: true,
                                className: "text-sm text-center",
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.engCurrentGrade"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.endEndOfYearTarget"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.engY11Target"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.engY11Estimate"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.engProgress"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="blue">Maths</td>
                          <td>
                            <InputField 
                              fieldName="previousdataSecondary.mathsPreviousGrade"
                              saveForm={saveForm}
                              onChangeHandler={numberOnChangeHandler}
                              rest={{
                                readOnly: true,
                                className: "text-sm text-center",
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.mathsCurrentGrade"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.mathsEndOfYearTarget"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.mathsY11Target"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.mathsY11Estimate"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={secondaryOptions}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                          <td>
                            <SelectField
                              fieldName={"dataSecondary.mathsProgress"}
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              optionsList={progressOptionList}
                              rest={{
                                className: "pep-select"
                              }}
                            />
                          </td>
                        </tr>
                        <FieldArray name="pupilUniqueInfo.subjectList">
                          {
                            ({form}) => {
                              return (
                                form.values.pupilUniqueInfo?.subjectList ?
                                  form.values.pupilUniqueInfo.subjectList.map((item, index) => {
                                    return (
                                      <SecondaryKs3SubjectRow 
                                        index={index}
                                        deleteKs3Subject={deleteKs3Subject}
                                        saveForm={saveForm}
                                        ks3CustomSubjectOnChangeHandler={ks3CustomSubjectOnChangeHandler}
                                        secondaryOptions={secondaryOptions}
                                        progressOptionList={progressOptionList}
                                      />
                                      // <tr key={index}>
                                      //   <td className="blue">
                                      //     <InputField 
                                      //       fieldName={`pupilUniqueInfo.subjectList[${index}].subName`}
                                      //       onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //       saveForm={saveForm}
                                      //       rest={{
                                      //         // className: "blue pep-input",
                                      //       }}
                                      //       restForLoader={{
                                      //         className: "absolute top-0 left-0 w-full h-full blue grid items-center"
                                      //       }}
                                      //     />
                                      //   </td>
                                      //   <td>
                                      //     {/* <InputField
                                      //       fieldName="previousdataSecondary.mathsPreviousGrade"
                                      //       saveForm={saveForm}
                                      //       onChangeHandler={numberOnChangeHandler}
                                      //       rest={{
                                      //         readOnly: true,
                                      //         className: "text-sm text-center",
                                      //       }}
                                      //     /> */}
                                      //   </td>
                                      //   <td>
                                      //     <SelectField
                                      //       fieldName={`pupilUniqueInfo.subjectList[${index}].curGrade`}
                                      //       onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //       saveForm={saveForm}
                                      //       optionsList={secondaryOptions}
                                      //       rest={{
                                      //         className: "pep-select"
                                      //       }}
                                      //     />
                                      //   </td>
                                      //   <td>
                                      //     <SelectField
                                      //       fieldName={`pupilUniqueInfo.subjectList[${index}].endYearTarget`}
                                      //       onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //       saveForm={saveForm}
                                      //       optionsList={secondaryOptions}
                                      //       rest={{
                                      //         className: "pep-select"
                                      //       }}
                                      //     />
                                      //   </td>
                                      //   <td>
                                      //     <SelectField
                                      //       fieldName={`pupilUniqueInfo.subjectList[${index}].target`}
                                      //       onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //       saveForm={saveForm}
                                      //       optionsList={secondaryOptions}
                                      //       rest={{
                                      //         className: "pep-select"
                                      //       }}
                                      //     />
                                      //   </td>
                                      //   <td>
                                      //     <SelectField
                                      //       fieldName={`pupilUniqueInfo.subjectList[${index}].expected`}
                                      //       onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //       saveForm={saveForm}
                                      //       optionsList={secondaryOptions}
                                      //       rest={{
                                      //         className: "pep-select"
                                      //       }}
                                      //     />
                                      //   </td>
                                      //   <td>
                                      //     <div className="flex-items-center-gap-3">
                                      //       <SelectField
                                      //         fieldName={`pupilUniqueInfo.subjectList[${index}].progress`}
                                      //         onChangeHandler={ks3CustomSubjectOnChangeHandler}
                                      //         saveForm={saveForm}
                                      //         optionsList={progressOptionList}
                                      //         rest={{
                                      //           className: "pep-select"
                                      //         }}
                                      //       />
                                      //       <i
                                      //         className="fa-solid fa-trash-can text-red-500 cursor-pointer"
                                      //         onClick={() => deleteKs3Subject({
                                      //           form,
                                      //           // index: rowIndex,
                                      //         })}
                                      //       >
                                      //       </i>
                                      //     </div>
                                      //   </td>
                                      // </tr>
                                    )
                                  }) : null
                              )
                            }
                          }
                        </FieldArray>
                      </tbody>
                    </table>
                    
                  </div>
                  
                </div>
                <FieldArray name="pupilUniqueInfo.subjectList">
                  {
                    ({ push, form }) => {
                      return (
                        <button
                          type="button"
                          className="blue-button px-5 py-2 text-sm"
                          onClick={() => addKs3Subject(form, push)}
                        >
                          Add +
                        </button>
                      )
                    }
                  }
                </FieldArray>
              </div>

              {/* Fifth Part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is the student on track to reach their English target this year? <span className="required">*</span>
                  </label>
                  <RadioField
                    fieldName="dataSecondary.engTargetThisYear"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No" },
                    ]}
                  />
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is the student on track to reach their Maths target this year? <span className="required">*</span>
                  </label>
                  <RadioField
                    fieldName="dataSecondary.mathsTargetThisYear"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    radioOptionsList={[
                      { innerValue: "Yes", outerValue: "Yes" },
                      { innerValue: "No", outerValue: "No" },
                    ]}
                  />
                </div>


                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is our child/young person on a bespoke, targeted curriculum which is not measured by age/phase or working towards qualifications?
                  </label>
                  <SelectField
                    fieldName={"dataSecondary.workingTowardsQualifications"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div className="table-box overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className=''>
                        <th className='blue border-collapse border-2 border-white'>Current pathway/curriculum/subject</th>
                        <th className='d-blue border-collapse border-2 border-white'>Currently working at</th>
                        <th className='purple border-collapse border-2 border-white'>Expectations/target for end of KS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName="dataSecondary.currentPathway"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                        </td>
                        <td className='text-center'>
                          <TextAreaField
                            fieldName="dataSecondary.currentlyWorking"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                          
                        </td>
                        <td className='text-center'>
                          <TextAreaField
                            fieldName="dataSecondary.expectationsAndTarget"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                          
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    From your assessments, is the child/young person making good progress? <span className="required">*</span>
                  </label>
                  <SelectField
                    fieldName={"dataSecondary.makingGoodProgress"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div>
                  <p className='font-medium'>How do you know and how it is measured?</p>
                  <TextAreaField
                    fieldName="dataSecondary.howItIsMeasured"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                  />
                </div>

                <div className="rounded-3xl bg-white w-full mt-4" >
                  <div className="flex flex-col gap-5">
                    <h1 className='font-medium text-md'>SDQ Summary</h1>
                    <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-8">
                      <div className="flex items-center gap-2">
                        <label htmlFor="" className=''>SDQ Score</label>
                        <InputField
                          fieldName="dataSecondary.sdqScore"
                          saveForm={saveForm}
                          onChangeHandler={numberOnChangeHandler}
                          rest={{
                            className: "py-1 px-2 rounded-full border-gray-300 border text-sm w-16",
                          }}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <label className="">SDQ Date <span className="required">*</span></label>
                        <DateField
                          dateChangeHandler={dateChangeHandler}
                          fieldName="dataSecondary.sdqDate"
                          saveForm={saveForm}
                        />
                        {/* <DateField /> */}
                      </div>
                    </div>
                    <p>Social Worker to provide the overall SDQ score. Discussion should take place about what impact this may have in school.</p>
                    <div className='flex flex-col gap-5 md:flex-row md:gap-7 md:items-center'>
                      <div className="flex gap-3 items-center">
                        <label className='font-medium'>School Report Uploaded? </label>
                        <RadioField
                          fieldName="dataSecondary.schoolReportUploaded"
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          radioOptionsList={[
                            { innerValue: "Yes", outerValue: "Yes" },
                            { innerValue: "No", outerValue: "No" },
                          ]}
                        />
                      </div>

                      <div className='flex  items-center'>
                        <div class='file file--upload'>                
                          <button 
                            type="button"  
                            className="upload-button px-5 py-2 text-sm" 
                            onClick={() => setIsOpen(true)}
                          >
                            Upload<i class="upload-icon fa-regular fa-cloud-arrow-up"></i>
                          </button>
                        </div>
                        <div className="pl-4 view-report">
                          <button type="button" className="btn pep-btn">View Reports <i class="fa-regular fa-angle-down"></i></button>
                          <ul className="scroll_add">
                            <FieldArray name="schoolUploadedFiles">
                              {
                                ({ form, remove }) => {
                                  return (
                                    form.values.schoolUploadedFiles && form.values.schoolUploadedFiles.map((result, index) => {
                                      return (
                                        <li key={index} className="flex justify-between items-center gap-3">
                                          <span
                                            onClick={() => downloadUploadedFile({
                                              blobKey: result.blobkey,
                                              fileName: result.fileName,
                                            })}
                                            className='flex justify-between items-center gap-3'>
                                            {result.fileShowName ? result.fileShowName : result.fileName}
                                          </span>
                                          <i
                                            className="fa-solid fa-circle-trash text-red-600"
                                            onClick={() => deleteUploadedFile({
                                              blobKey: result.blobkey,
                                              fileName: result.fileName,
                                              deleteFile: () => remove(index),
                                            })}
                                          >
                                          </i>
                                        </li>
                                      )
                                    })
                                  )
                                }
                              }
                            </FieldArray>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </>
        }
        <Field>
          {
            ({form}) => {
              return (
                <UploadModal
                  isOpen={isOpen}
                  closeModal={() => setIsOpen(false)}
                  isSecondary="Y"
                  listHandler={uploadedDocumentListHandler}
                  uploadUrl={NCCReportApi.saveSchoolReport}
                  responseObjectName={"schoolReport"}
                  form={form}
                />
              )
            }
          }
        </Field>
      </Form>
    </Formik>

  )
}

export default NccDataHoc(NCCDataSecondary);
