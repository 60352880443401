import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import PupilContext from '../../../Components/context/PupilContext';
import { settings } from '../../../Components/utils/Settings';

const PepDashboard = () => {
  const { pupilInfo, activePupilId, dateOfMeeting } = useContext(PupilContext);
  const roleId = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");
  const [isLoading, setIsLoading] = useState(false);
  const [smartTargetList, setSmartTargetList] = useState([]);
  const [secondarymodule, setSecondaryModule] = useState({});
  const [exclusionCount, setExclusionCount] = useState(0);
  const [Percentage, setPercentage] = useState("0.0");
  const [pepQualityData, setPepQualityData] = useState({});
  const [pepMeeting, setPepMeeting] = useState({});
  const [pupilPremiumAmount, setPupilPremiumAmount] = useState("");
  const [pupilsen, setPupilsen] = useState("");

  useEffect(() => {
    pupilInfo.pupilId && axios(`${settings.API_HOST}/rest/fetchDashboardModule?pupilId=${pupilInfo.pupilId}&schoolId=${schoolId}&userId=${userId}&dateOfMeeting=${dateOfMeeting}&termofpep=${pupilInfo.termOfPep}`)
      .then(res => {
        console.log(res.data.pppCount,"ppp amount");
        setSmartTargetList(res.data.pepSmartTargetNewRowList ? res.data.pepSmartTargetNewRowList : null);
        setSecondaryModule(res.data.pepSecondaryData ? res.data.pepSecondaryData : null);
        setExclusionCount(res.data.exclusionCount ? res.data.exclusionCount : 0);
        setPercentage(CalculateAttendancePercentage(res.data.attendanceDTO ? res.data.attendanceDTO : null));
        setPepQualityData(res.data.pepQualityData ? res.data.pepQualityData : null);
        setPepMeeting(res.data.meeting ? res.data.meeting : null);
        setPupilPremiumAmount(res.data.pppCount ? res.data.pppCount : "");
        setPupilsen((res.data.pupil && res.data.pupil.sen) ? res.data.pupil.sen : "");

      });
  }, [activePupilId, pupilInfo.termOfPep]);

  const CalculateAttendancePercentage = (data) => {
    let attpercentage = 0.0;
    if (data != null) {
      let presentCount = (data.presentCount ? data.presentCount : 0);
      let totalSessions = (data.totalSessions ? data.totalSessions : 0);
      if (totalSessions != 0) {
        attpercentage = ((presentCount * 100) / totalSessions);
      }
    }
    return attpercentage.toFixed(1);
  }
  // fetchDashboardModule
  return (
    <div className="w-full">
      <div className="dashboard mr-6">
        <div className="dashboard-header">
          <ul className="flex justify-between">
            <li className="page-title">
              <h3 className="">PEP Dashboard for <b>{
                pupilInfo && pupilInfo.fullname
                  ? pupilInfo.fullname
                  : ""
              }</b></h3>
            </li>
            <li className="school-detail">
              <span className="name-title">School Name</span>
              <label className="mane-detail">{
                pupilInfo && pupilInfo.schoolname
                  ? pupilInfo.schoolname
                  : ""
              }</label>
            </li>
            <li className="export">
              <button className="btn btn-purple">Export</button>
            </li>
          </ul>
        </div>
        <div className="dashboard-filter">
          <ul className="flex filter-detail justify-brtween py-4">
            <li className="pr-16">
              <span className="name-title">Term of PEP</span>
              <label className="mane-detail">{dateOfMeeting ? pupilInfo.termOfPep : ""}</label>
            </li>
            <li className="pr-16">
              <span className="name-title">Date of Meeting</span>
              <label className="mane-detail">{dateOfMeeting}</label>
            </li>
            <li className="pr-16">
              <span className="name-title">Time</span>
              <label className="mane-detail">5:11</label>
            </li>
            <li className="pr-16">
              <span className="name-title">Year Group</span>
              <label className="mane-detail">{
                pupilInfo && pupilInfo.classYear
                  ? pupilInfo.classYear
                  : ""
              }</label>
            </li>
            <li className="pr-16">
              <span className="name-title">Send</span>
              <label className="mane-detail">{pupilsen}</label>
            </li>
          </ul>
        </div>
        <div className="dashboard-box w-full">
          <div className="flex dhasboard-overview justify-between">
            <div className="dash-box p-6 m-2 w-full text-center rounded-2xl bg-white">
              <div className="dash-score red">
                <span className="value">{Percentage} %</span>
              </div>
              <div className="dash-title">Current Attendance</div>
            </div>
            <div className="dash-box m-2 w-full p-6 text-center rounded-2xl bg-white">
              <div className="dash-score green">
                <span className="value">{exclusionCount}</span>
              </div>
              <div className="dash-title">Exclusions to Date</div>
            </div>
            <div className="dash-box m-2 w-full p-6 text-center rounded-2xl bg-white">
              <div className="dash-score orange">
                <span className="value">{(secondarymodule && secondarymodule.engCurrentGrade) ? secondarymodule.engCurrentGrade : ""}</span>
              </div>
              <div className="dash-title">English Latest Level</div>
            </div>
            <div className="dash-box m-2 w-full p-6 text-center rounded-2xl bg-white">
              <div className="dash-score red">
                <span className="value">{(secondarymodule && secondarymodule.engTargetThisYear) ? secondarymodule.engTargetThisYear : ""}</span>
              </div>
              <div className="dash-title">English on Track?</div>
            </div>
            <div className="dash-box m-2 w-full p-6 text-center rounded-2xl bg-white">
              <div className="dash-score red">
                <span className="value">{(secondarymodule && secondarymodule.mathsCurrentGrade) ? secondarymodule.mathsCurrentGrade : ""}</span>
              </div>
              <div className="dash-title">Math latest level</div>
            </div>
            <div className="dash-box m-2 w-full p-6 text-center rounded-2xl bg-white">
              <div className="dash-score green">
                <span className="value">{(secondarymodule && secondarymodule.mathsTargetThisYear) ? secondarymodule.mathsTargetThisYear : ""}</span>
              </div>
              <div className="dash-title">Math on Track?</div>
            </div>
          </div>
        </div>
        <div className="table-box w-full">
          <div className="table-outer p-8 my-4 bg-white">
            <table className="table-fixed text-center w-full">
              <thead>
                <tr>
                  <th className="w-1/2 ...">Actions</th>
                  <th className="w-1/5 ...">who?</th>
                  <th className="w-1/5 ...">When?</th>
                  <th className="w-1/5 ...">Completed?</th>
                </tr>
              </thead>
              <tbody>
                {smartTargetList && smartTargetList.map((result) => {
                  if (result.st1What != null && result.st1What.length > 0) {
                    return (
                      <tr>
                        <td>{result.st1What}</td>
                        <td>{result.st1Who}</td>
                        <td>{result.st1When}</td>
                        <td>{result.st1VsAgreed}</td>
                      </tr>
                    );
                  }
                })
                }
              </tbody>
            </table>
          </div>
          <div className="dashboard-btm-box w-full">
            <div className="flex dhasboard-btm-overview justify-between">
              <div className="dash-btm-box m-2 w-full p-6 text-center rounded-2xl bg-white">
                <div className="dash-btm-title">Pupil Premium Sent This Year</div>
                <div className="btm-payment blue">
                  <label><i className="far fa-usd-square"></i></label>
                  <span className="values">£ {pupilPremiumAmount}</span>
                </div>
              </div>
              <div className="dash-btm-box m-2 w-full p-6 text-center rounded-2xl bg-white">
                <div className="dash-btm-title">PEP Grade</div>
                <div className="btm-payment green">
                  <label><i className="fal fa-award"></i></label>
                  <span className="values">{pepQualityData ? pepQualityData.pepGrade : ""}</span>
                </div>
              </div>
              <div className="dash-btm-box m-2 w-full p-6 text-center rounded-2xl bg-white">
                <div className="dash-btm-title">Date of next PEP</div>
                <div className="btm-payment">
                  <span className="value">{(pepMeeting && pepMeeting.nextMeetingDate && pepMeeting.nextMeetingDate!="null null") ? pepMeeting.nextMeetingDate : ""}</span>
                </div>
              </div>
              <div className="dash-btm-box m-2 w-full p-6 text-center rounded-2xl bg-white">
                <div className="dash-btm-title">Term of next PEP</div>
                <div className="btm-payment">
                  <select className="select"
                    value={(pepMeeting && pepMeeting.termOfPepofNextMeeting) ? pepMeeting.termOfPepofNextMeeting : ""}>
                    <option></option>
                    <option>Autumn 2020</option>
                    <option>Spring 2021</option>
                    <option>Summer 2021</option>
                    <option>Autumn 2021</option>
                    <option>Spring 2022</option>
                    <option>Summer 2022</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PepDashboard;
