import React, { forwardRef } from 'react';
import { ToastContainer } from 'react-toastify';
import NCCSideBar from './NCCSideBar';


const NCC = (props, ref) => {
  return (
    <>
      {props.hasSideBar && <NCCSideBar ref={ref}/>}
      <div className="w-full rounded-custom-content flex flex-col">
        <props.Component {...props} />
      </div>
      <ToastContainer />
    </>
  );
}

export default forwardRef(NCC);
