import React, { useContext, useEffect, useReducer, useState } from 'react';
import axios from 'axios';
import Pagination from '../../../Components/Pagination';
import { settings } from '../../../Components/utils/Settings';
import { NCCReportApi } from '../../../constants/apiUrls';
import { useHistory } from 'react-router-dom';
import { routeUrl } from '../../../Components/utils/RouteUrl';
import { setLoading, setPepRejected, setCurrentPage as setCurrentPageAction, setResultsPerPage as setResultsPerPageAction, setSearch as setSearchAction, setPepInadequate } from '../../../constants/reducerActions';
import headerFilterReducer from '../../../reducers/NCC/headerFilterReducer';
import PepHeaderFilterLoadingTemplate from '../../../Components/NCC/PepHeaderFilter/PepHeaderFilterLoadingTemplate';




const initialState = {
  statusList: [],
  isLoading: false,
  resultsPerPage: 10,
  currentPage: 1,
  search: "",
}


const PepInadequate = () => {
  const [state, dispatch] = useReducer(headerFilterReducer, initialState);

  const {
    statusList,
    isLoading,
    currentPage,
    resultsPerPage,
    search,
  } = state;

  // const [statuslist, setStatusList] = useState([]);
  const userId = localStorage.getItem("userId");
  // const [currentPage, setCurrentPage] = useState(1);
  // const [resultsPerPage, setResultsPerPage] = useState(10);
  const indexOfLastResult = currentPage * resultsPerPage;
  const indexOfFirstResult = indexOfLastResult - resultsPerPage;
  let currentResults = statusList && statusList.slice(indexOfFirstResult, indexOfLastResult);
  const pagination = (number) => setCurrentPage(number);
  const history = useHistory();
  useEffect(() => {
    dispatch({ type: setLoading, payload: { isLoading: true } });
    axios(`${settings.API_HOST}/rest/pepInadequate?userId=${userId}`)
      .then(res => {
        dispatch({ type: setPepInadequate, payload: { statusList: res.data.pepStatusDto } })
      })
      .catch(() => {
        dispatch({ type: setLoading, payload: { isLoading: false } });
      });
  }, []);

  const setCurrentPage = (currentPage) => {
    dispatch({ type: setCurrentPageAction, payload: { currentPage } });
  }

  const setResultsPerPage = (resultsPerPage) => {
    dispatch({ type: setResultsPerPageAction, payload: { resultsPerPage } });
  }

  const setSearch = (search) => {
    dispatch({ type: setSearchAction, payload: { search } });
  }

  const filterStatusList = () => {
    const regex = new RegExp(search, "i");
    const filterList = currentResults && currentResults.length > 0 && currentResults?.filter(item => {
      return regex.test(item[1]);
    });
    return filterList;
  }

  const filteredStatusList = filterStatusList();

  const componentToRender = {
    Component: null,
    componentTitle: null,
    reportName: "PepInadequate.xlsx"
  }

  const exportExcel = () => {
    const a = document.createElement("a");

    const url = NCCReportApi.exportFilterReportExcel({
      userId: userId,
      pepHeaderStatus: "Inadequate",
    });
    axios.get(url, { responseType: "blob" })
      .then((res) => {
        let objectUrl = window.URL.createObjectURL(res.data);
        a.href = objectUrl;
        a.download = componentToRender.reportName;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
      .catch(err => {
        const error = err;
        console.log(err);
        console.log(err);
      })
  }

  const routingHeader = (e) => {
    let routerAddress = e.target.value;
    switch (routerAddress) {
      case "Rejected":
        history.push(routeUrl.pep_rejected);
        break;

      case "Inadequate":
        history.push(routeUrl.pep_inadequate);
        break;

      case "Approved":
        history.push(routeUrl.pep_approved);
        break;

      case "Approved with action":
        history.push(routeUrl.pep_approved_with_action);
        break;

      case "Not approved":
        history.push(routeUrl.pep_not_approved);
        break;

      case "Overdue":
        history.push(routeUrl.pep_is_overdue);
        break;

      case "Due":
        history.push(routeUrl.pep_is_due);
        break;
    }
  }
  return (
    <div className="flex-1 bg-container-custom rounded-custom-content">
      <div className="grid lg:grid-cols-1 grid-cols-none mt-5">
        <ul className="flex items-center gap-4">
          <li>
            PEP Status
          </li>
          <li>
            <select className="pep-select" onChange={(e) => routingHeader(e)}>
              <option></option>
              <option value="Rejected">Rejected</option>
              <option value="Completed">Completed</option>
              <option value="Inadequate" selected>Inadequate</option>
              <option value="Approved">Approved</option>
              <option value="Approved with action">Approved with action</option>
              <option value="Not approved">Not approved</option>
              <option value="Overdue">Overdue</option>
              <option value="Due">Due</option>
            </select>
          </li>
          <li>
            <button className="blue-button complete-button text-sm" onClick={() => exportExcel()}>Export to Excel</button>
          </li>
        </ul>
      </div>
      <div className="grid lg:grid-cols-1 grid-cols-none mt-5">
        <div className="bg-white table-outer p-8 tableview overflow-x-auto">
          <div className='flex justify-between items-center mb-4 border-b border-gray-200 pb-3'>
            <div className=''>
              <span className="pr-3 text-sm">Show</span>
              <select
                className="rounded-custom-content border p-1 text-sm border-gray-200"
                value={resultsPerPage}
                onChange={(e) => setResultsPerPage(parseInt(e.target.value))}
              >
                <option value=""></option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pl-3 text-sm">entries</span>
            </div>
            <div className="flex gap-5 items-center ">
              <div className="relative flex items-center">
                <input
                  type="text"
                  className="border rounded-full border-gray-300 p-2 text-sm"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          <table className="text-left table-box w-full table-auto">
            <thead>
              <tr>
                <th className="blue w-64">Name</th>
                <th className="d-blue w-64">Surname</th>
                <th className="purple w-64">School Name</th>
                <th className="orange">Year Group</th>
                <th className="d-orange">Date of PEP</th>
                <th className="d-pink">Term of PEP</th>
                <th className="pink">PEP Status</th>
                <th className="green">PEP Sign Off</th>
                <th className="l-green">PEP Grade</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ?
                <PepHeaderFilterLoadingTemplate /> : 
                filteredStatusList && filteredStatusList.map((result) => {
                  return (
                    <tr>
                         <td>{result.forename}</td>
                        <td>{result.surname}</td>
                        <td>{result.schoolName}</td>
                        <td>{result.classYear}</td>
                        <td>{result.dateOfMeeting}</td>
                        <td>{result.termOfPep}</td>
                        <td>{result.pepStatus}</td>
                        <td>{result.pepSignOff}</td>
                        <td>{result.pepGrade}</td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <Pagination
            resultsPerPage={resultsPerPage}
            totalRecords={statusList ? statusList.length : 0}
            paginate={pagination}
            currentPage={currentPage}
            showEntries={true}
            hasNextOrPreviousButton={true}
          />
        </div>
      </div>
    </div>
  )
};

export default PepInadequate;
