import axios from 'axios';
import moment from 'moment';
import React from 'react'
import { useContext } from 'react';
import Swal from 'sweetalert2';
import { NCCReportApi } from '../../../constants/apiUrls';
import IsCompletedContext from '../../context/IsCompletedContext';
import PupilContext from '../../context/PupilContext';
import { getYearGroup, handleEmptyValuesForSaving, showErrorToast, showWarningToast } from '../../utils/utilityFunctions';


const PupilVoiceHoc = (OriginalComponent) => {
  const WrappedComponent = () => {
    const { setIsCompleted } = useContext(IsCompletedContext);
    const {pupilInfo} = useContext(PupilContext);


    let postUrl = ``;
    if(getYearGroup(pupilInfo.classYear) !== "post16") {
      postUrl = NCCReportApi.savePupilVoice;
    } else {
      postUrl = NCCReportApi.savePupilVoicePost16;
    }

    const saveForm = ({ setIsLoading, values, objectName, saveCallback = ()=>{} }) => {
      if(pupilInfo.dateofmeeting) {
        setIsLoading(true);
        const formData = new FormData();
        Object.entries(values[objectName]).forEach(([key, value]) => {
          formData.append(`${objectName}.${key}`, handleEmptyValuesForSaving(value));
        })
        axios.post(postUrl, formData)
          .then((res) => {
            console.log(res.data, "Saving response pupil voice");
            setIsLoading(false);
            saveCallback();
          })
          .catch(() => {
            showErrorToast("Problem while saving")
            setIsLoading(false);
          })
      } else {
        showWarningToast("Please select Date of Meeting")
      }
    }

    const onChangeHandler = ({
      fieldName,
      fieldValue,
      setIsLoading,
      saveForm,
      setFieldValue,
      values,
    }) => {
      setFieldValue(fieldName, fieldValue);
      const [objectName, attributeName] = fieldName.split(".");
      const updatedValues = {
        ...values,
      }
      updatedValues[objectName][attributeName] = fieldValue;
      saveForm({ 
        setIsLoading, 
        objectName, 
        values: updatedValues, 
      });
    }

    const numberOnChangeHandler = ({
      setFieldValue,
      fieldValue,
      saveForm,
      setIsLoading,
      maxDigit,
      fieldName, }) => {
      const onlyNumberCheckRegex = /^[-+]?[0-9]+$/;
      if (maxDigit) {
        if ((onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") && (fieldValue <= maxDigit)) {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,

          })
        }
      } else {
        if (onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") {
          setFieldValue(fieldName, fieldValue);
          saveForm({
            setIsLoading,
          })
        }
      }
    }

    const dateChangeHandler = ({ date, form, field, setIsLoading }) => {
      const dateToSave = date ? moment(date).format("DD/MM/YYYY") : null;
      const values = form.values;
      form.setFieldValue(field.name, dateToSave);
      saveForm({
        setIsLoading,
        values
      })

    }

    const exportPdf = (reportName) => {
      const a = document.createElement("a");
      const url = NCCReportApi.exportPdfReports({
        reportName,
        pupilId: pupilInfo.pupilId,
        schoolId: pupilInfo.schoolId,
        classYear: pupilInfo.classYear,
        termofpep: pupilInfo.termOfPep,
        pupilsName: pupilInfo.fullname,
        dateofmeeting: pupilInfo.dateofmeeting,
      });
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = reportName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
        .catch(err => {
          const error = err;
          console.log(err);
          console.log(err);
        })
    }

    const formValidationWrapper = ({
      fieldName,
      setCompleteButtonLoader,
      saveForm = () => { },
      saveCallback = () => { },
      successCallback = () => { },
      checkForErrors = (errors) => { },
      objectName,
    }) => {
      const formValidation = (values, formik) => {
        if(pupilInfo.dateofmeeting) {
          const updatedValues = { ...values };
          const error = checkForErrors(values);
          
          if (error && error.trim().length > 0) {
            Swal.fire({
              iconColor: "red",
              icon: 'warning',
              title: error,
              showConfirmButton: false,
              timer: 1500
            })
          } else {
            successCallback(updatedValues);
            
            const saveCallbackWrapper = () => {
              formik.setFieldValue(fieldName, 1);
              saveCallback();
            }
            
            saveForm({
              values: updatedValues,
              setIsLoading: setCompleteButtonLoader,
              saveCallback: saveCallbackWrapper,
              objectName,
            })
          }
        } else {
          showWarningToast("Date of meeting is not selected")
        }
      }
      return formValidation;
    }

    const isCompletedCallback = (msg) => {
      setIsCompleted("pupilVoiceIsCompleted", true);
      Swal.fire({
        iconColor: "#3daf49",
        iconHtml: '<i class="fa-regular fa-check"></i>',
        title: msg,
        showConfirmButton: true,
        button: "Ok",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'blue-button text-sm px-6 py-2 text-md' //insert class here
        }
      })
    }
    
    return (
      <OriginalComponent
        saveForm={saveForm}
        onChangeHandler={onChangeHandler}
        numberOnChangeHandler={numberOnChangeHandler}
        dateChangeHandler={dateChangeHandler}
        exportPdf={exportPdf}
        formValidationWrapper={formValidationWrapper}
        isCompletedCallback={isCompletedCallback}
      />
    )
  }

  return WrappedComponent;
}

export default PupilVoiceHoc;
