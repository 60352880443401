import axios from 'axios';
import { Form, Formik } from 'formik';
import React, { useEffect, useContext, useReducer } from 'react';
import PupilContext from '../../../../Components/context/PupilContext';
import { settings } from '../../../../Components/utils/Settings';
import { setPepCincpSecondary } from '../../../../constants/reducerActions';
import cincpReducer from '../../../../reducers/cincpReducer';
import PepSecondaryHeader from '../../PepSecondaryHeader';
import CINCPHoc from './CINCPHoc';
import CINCPInputField from './CINCPInputField';
import CINCPTextArea from './CINCPTextArea';
import PepCinCpHistoryModule from './PepCinCpHistoryModel';

const initialState = {
  keyStage1Phonics: "",
  keyStage1Writing: "",
  keyStage1Reading: "",
  keyStage1Maths: "",
  keyStage2Spag: "",
  keyStage2Writing: "",
  keyStage2Reading: "",
  keyStage2Maths: "",
  keyStage4English: "",
  keyStage4Maths: "",
  comment: "",
  attendanceEndOfAutumn: "",
  attendanceEndOfSpring: "",
  attendanceEndOfSummer: "",
  englishEndOfAutumn: "",
  englishEndOfSpring: "",
  englishEndOfSummer: "",
  mathsEndOfAutumn: "",
  mathsEndOfSpring: "",
  mathsEndOfSummer: "",
  sub1EndOfAutumn: "",
  sub1EndOfSpring: "",
  sub1EndOfSummer: "",
  sub2EndOfAutumn: "",
  sub2EndOfSpring: "",
  sub3EndOfSummer: "",
  sub3EndOfAutumn: "",
  sub3EndOfSpring: "",
  sub3EndOfSummer: "",
  sub4EndOfAutumn: "",
  sub4EndOfSpring: "",
  sub4EndOfSummer: "",
  sub5EndOfAutumn: "",
  sub5EndOfSpring: "",
  sub5EndOfSummer: "",
  commentsDevelopmentEndOfAutumn: "",
  commentsDevelopmentEndOfSpring: "",
  commentsDevelopmentEndOfSummer: "",
  educationNeedThisChild: "",
  barrierToMakingGoodProgress: "",
  spendingPpgChild: "",
  helpOvercomeTheBarriers: "",
  childIsNotInSchool: "",
  successLookLike: "",
}

const PepCinCPSecondary = ({ textFieldHandler, saveField, historyModel, openHistoryPopup }) => {

  const { activePupilId } = useContext(PupilContext);

  // State from CIN CP Reducer
  const [cipCp, dispatch] = useReducer(cincpReducer, initialState);

  // Initial Formik State
  const initialFormState = {
    keyStage1Phonics: cipCp.keyStage1Phonics,
    keyStage1Writing: cipCp.keyStage1Writing,
    keyStage1Reading: cipCp.keyStage1Reading,
    keyStage1Maths: cipCp.keyStage1Maths,
    keyStage2Spag: cipCp.keyStage2Spag,
    keyStage2Writing: cipCp.keyStage2Writing,
    keyStage2Reading: cipCp.keyStage2Reading,
    keyStage2Maths: cipCp.keyStage2Maths,
    keyStage4English: cipCp.keyStage4English,
    keyStage4Maths: cipCp.keyStage4Maths,
    comment: cipCp.comment,
    attendanceEndOfAutumn: cipCp.attendanceEndOfAutumn,
    attendanceEndOfSpring: cipCp.attendanceEndOfSpring,
    attendanceEndOfSummer: cipCp.attendanceEndOfSummer,
    englishEndOfAutumn: cipCp.englishEndOfAutumn,
    englishEndOfSpring: cipCp.englishEndOfSpring,
    englishEndOfSummer: cipCp.englishEndOfSummer,
    mathsEndOfAutumn: cipCp.mathsEndOfAutumn,
    mathsEndOfSpring: cipCp.mathsEndOfSpring,
    mathsEndOfSummer: cipCp.mathsEndOfSummer,
    sub1EndOfAutumn: cipCp.sub1EndOfAutumn,
    sub1EndOfSpring: cipCp.sub1EndOfSpring,
    sub1EndOfSummer: cipCp.sub1EndOfSummer,
    sub2EndOfAutumn: cipCp.sub2EndOfAutumn,
    sub2EndOfSpring: cipCp.sub2EndOfSpring,
    sub3EndOfSummer: cipCp.sub3EndOfSummer,
    sub3EndOfAutumn: cipCp.sub3EndOfAutumn,
    sub3EndOfSpring: cipCp.sub3EndOfSpring,
    sub3EndOfSummer: cipCp.sub3EndOfSummer,
    sub4EndOfAutumn: cipCp.sub4EndOfAutumn,
    sub4EndOfSpring: cipCp.sub4EndOfSpring,
    sub4EndOfSummer: cipCp.sub4EndOfSummer,
    sub5EndOfAutumn: cipCp.sub5EndOfAutumn,
    sub5EndOfSpring: cipCp.sub5EndOfSpring,
    sub5EndOfSummer: cipCp.sub5EndOfSummer,
    commentsDevelopmentEndOfAutumn: cipCp.commentsDevelopmentEndOfAutumn,
    commentsDevelopmentEndOfSpring: cipCp.commentsDevelopmentEndOfSpring,
    commentsDevelopmentEndOfSummer: cipCp.commentsDevelopmentEndOfSummer,
    educationNeedThisChild: cipCp.educationNeedThisChild,
    barrierToMakingGoodProgress: cipCp.barrierToMakingGoodProgress,
    spendingPpgChild: cipCp.spendingPpgChild,
    helpOvercomeTheBarriers: cipCp.helpOvercomeTheBarriers,
    childIsNotInSchool: cipCp.childIsNotInSchool,
    successLookLike: cipCp.successLookLike,
  }

  useEffect(() => {
    activePupilId && axios(`${settings.API_HOST}/rest/cin-cp?pupilId=${activePupilId}`)

      .then(res => {
        dispatch({ type: setPepCincpSecondary, payload: { data: res.data.pepEyfsCinCp } })
      });

  }, [activePupilId]);


  return (
    <>
      <useToastContainer />
      <PepSecondaryHeader
        subHeading=""
        heading="CIN / CP / PLAC Secondary - Education Info"
        buttons={[
          <button className="blue-button px-5 py-2  text-sm">Complete</button>,
        ]}
      />
      <Formik
        initialValues={initialFormState}
        enableReinitialize
      >
        <Form>
          <div className="secondary-section w-full">
            <div className="rounded-3xl bg-white p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="blue rounded-3xl w-full px-4 py-2">Prior Attainment</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table class="table-fixed text-left w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32">EYFS</th>
                        <th className="d-blue w-32">GLD achieved?</th>
                        <th className="purple w-64"></th>
                        <th className="orange w-32">Phonics Score</th>
                        <th className="d-orange w-64"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">Key stage 1</td>
                        <td>Phonics</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Phonics"
                          />
                          {/* {cipCp.keyStage1Phonics} */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Writing"
                          />
                          {/* {cipCp.keyStage1Writing} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Reading"
                          />
                          {/* {cipCp.keyStage1Reading} */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage1Maths"
                          />
                          {/* {cipCp.keyStage1Maths} */}
                        </td>
                      </tr>
                      <tr>
                        <td rowspan="2">Key stage 2</td>
                        <td>SPaG</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Spag"
                          />
                          {/* {cipCp.keyStage2Spag} */}
                        </td>
                        <td>Writing</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Writing"
                          />
                          {/* {cipCp.keyStage2Writing} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Reading</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Reading"
                          />
                          {/* {cipCp.keyStage2Reading} */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage2Maths"
                          />
                          {/* {cipCp.keyStage2Maths} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Key stage 4</td>
                        <td>English</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4English"
                          />
                          {/* {cipCp.keyStage4English} */}
                        </td>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="keyStage4Maths"
                          />
                          {/* {cipCp.keyStage4Maths} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Comments</td>
                        <td colspan="4">
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="comment"
                          />
                          {/* {cipCp.comment} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="flex w-full py-4">
                <h3 className="pink rounded-3xl w-full px-4 py-2">Latest Attendance and Attainment (Secondary)</h3>
              </div>
              <div className="table-box w-full">
                <div className="table-outer rounded-3xl bg-white">
                  <table class="table-fixed text-center w-full">
                    <thead>
                      <tr>
                        <th className="blue w-32"></th>
                        <th className="d-blue w-4/12">End of Autumn 2021</th>
                        <th className="purple w-4/12">End of Spring 2022</th>
                        <th className="orange w-4/12">End of Summer 2022</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Attendance</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfAutumn"
                          />
                          {/* {cipCp.attendanceEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSpring"
                          />
                          {/* {cipCp.attendanceEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="attendanceEndOfSummer"
                          />
                          {/* {cipCp.attendanceEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>English</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="englishEndOfAutumn"
                          />
                          {/* {cipCp.englishEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="englishEndOfSpring"
                          />
                          {/* {cipCp.englishEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="englishEndOfSummer"
                          />
                          {/* {cipCp.englishEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Maths</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfAutumn"
                          />
                          {/* {cipCp.mathsEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfSpring"
                          />
                          {/* {cipCp.mathsEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="mathsEndOfSummer"
                          />
                          {/* {cipCp.mathsEndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {/* <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1"
                          /> */}
                          {cipCp.sub1}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfAutumn"
                          />
                          {/* {cipCp.sub1EndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfSpring"
                          />
                          {/* {cipCp.sub1EndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub1EndOfSummer"
                          />
                          {/* {cipCp.sub1EndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {cipCp.sub2}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2EndOfAutumn"
                          />
                          {/* {cipCp.sub2EndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub2EndOfSpring"
                          />
                          {/* {cipCp.sub2EndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfSummer"
                          />
                          {/* {cipCp.sub3EndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {cipCp.sub3}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfAutumn"
                          />
                          {/* {cipCp.sub3EndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfSpring"
                          />
                          {/* {cipCp.sub3EndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub3EndOfSummer"
                          />
                          {/* {cipCp.sub3EndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {cipCp.sub4}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfAutumn"
                          />
                          {/* {cipCp.sub4EndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfSpring"
                          />
                          {/* {cipCp.sub4EndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub4EndOfSummer"
                          />
                          {/* {cipCp.sub4EndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {cipCp.sub5}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub5EndOfAutumn"
                          />
                          {/* {cipCp.sub5EndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub5EndOfSpring"
                          />
                          {/* {cipCp.sub5EndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="sub5EndOfSummer"
                          />
                          {/* {cipCp.sub5EndOfSummer} */}
                        </td>
                      </tr>
                      <tr>
                        <td>Comment</td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfAutumn"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfAutumn} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSpring"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfSpring} */}
                        </td>
                        <td>
                          <CINCPInputField
                            onChangeHandler={textFieldHandler}
                            saveField={saveField}
                            fieldName="commentsDevelopmentEndOfSummer"
                          />
                          {/* {cipCp.commentsDevelopmentEndOfSummer} */}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="rounded-3xl bg-white mt-4 p-4 w-full">
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the main educational needs of this child? <i class="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("education_need_this_child")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"educationNeedThisChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.educationNeedThisChild}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What are the barriers to making good progress? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("barrier_to_making_good_progress")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"barrierToMakingGoodProgress"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.barrierToMakingGoodProgress}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">How is the school spending PPG for the child? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("spending_ppg_child")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"spendingPpgChild"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.spendingPpgChild}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What Additional support might help overcome the barriers? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("help_overcome_the_barriers")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"helpOvercomeTheBarriers"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.helpOvercomeTheBarriers}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">If this child is NOT in school? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("child_is_not_in_school")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"childIsNotInSchool"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.childIsNotInSchool}></textarea> */}
                  </div>
                </div>
              </div>
              <div className="py-2 widebox-container">
                <div className="pb-2">
                  <p className="inline-block">What would success look like? <i className="fas fa-folder-open cursor-pointer" onClick={() => openHistoryPopup("success_look_like")}></i></p>
                  <div className="textarea-block mt-1 w-full">
                    <CINCPTextArea
                      placeholder="Comment"
                      fieldName={"successLookLike"}
                      fieldHandler={textFieldHandler}
                      saveField={saveField}
                      rest={""}
                    />
                    {/* <textarea className="rounded-2xl px-2 py-4 textarea w-full" placeholder="Comment" value={cipCp.successLookLike}></textarea> */}
                  </div>
                </div>
              </div>
            </div>
            
            {historyModel}

          </div>
        </Form>
      </Formik>
    </>
  )
}

export default CINCPHoc(PepCinCPSecondary);