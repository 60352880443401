import React, { useContext } from 'react'
import PupilContext from '../../../Components/context/PupilContext'
import { getYearGroup } from '../../../Components/utils/utilityFunctions';
import NCCDataPrimary from "./components/NCCDataPrimary";
import NCCDataEyfs from "./components/NCCDataEyfs";
import NCCDataSecondary from "./components/NCCDataSecondary";
import NCCDataPost16 from "./components/NCCDataPost16";
import PepSecondaryHeader from '../../Pep/PepSecondaryHeader';
import { NCCReportApi } from '../../../constants/apiUrls';
import axios from 'axios';



const NCCData = () => {

  const schoolId = localStorage.getItem("schoolId");

  const {pupilInfo} = useContext(PupilContext);
  
  const componentToRender = {
    Component: null,
    componentTitle: "",
    reportName: ""
  }

  const yearGroup = getYearGroup(pupilInfo.classYear);
  
  switch(yearGroup) {

    case("eyfs"):
      componentToRender.Component = <NCCDataEyfs />;
      componentToRender.componentTitle = "Early Years";
      componentToRender.reportName = "nccDataEyfs"; 
      break;
      
      case("primary"):
      componentToRender.Component = <NCCDataPrimary />;
      componentToRender.componentTitle = "Primary";
      componentToRender.reportName = "nccDataPrimary";
      break;
      
      case("secondary"):
      componentToRender.Component = <NCCDataSecondary />;
      componentToRender.componentTitle = "Secondary";
      componentToRender.reportName = "nccDataSecondary";
      break;
      
      case("post16"):
      componentToRender.Component = <NCCDataPost16 />;
      componentToRender.componentTitle = "POST-16";
      componentToRender.reportName = "nccDataPost16";
      break;
  }
  

  const exportPdf = (reportName) => {
    const a = document.createElement("a");
    const url = NCCReportApi.exportPdfReports({
      reportName,
      pupilId: pupilInfo.pupilId,
      schoolId: pupilInfo.schoolId,
      classYear: pupilInfo.classYear,
      termofpep: pupilInfo.termOfPep,
      pupilsName: pupilInfo.fullname,
      dateofmeeting: pupilInfo.dateofmeeting,
      pupilSen: "",
    });
    axios.get(url, { responseType: "blob" })
      .then((res) => {
        let objectUrl = window.URL.createObjectURL(res.data);
        a.href = objectUrl;
        a.download = componentToRender.reportName;
        a.click();
        window.URL.revokeObjectURL(objectUrl);
        a.remove();
      })
      .catch(err => {
        const error = err;
        console.log(err);
        console.log(err);
      })
  }

  return (
    <>
      {/* <PepSecondaryHeader 
        heading={`NCC PEP - Data ${componentToRender.componentTitle}`}
        buttons={[
          <button className="blue-button complete-button  text-sm">Complete</button>,
          <div class="export-btn">
            <span className="blue-button px-5 py-2  text-sm">Export</span>
            <ul>
              <li>
                <button onClick={exportPdf}>Full Pep</button>
              </li>
              <li>
                <button onClick={exportPdf}>PDF</button>
              </li>
            </ul>
          </div>
        ]}
      /> */}
        {componentToRender.Component !== null && componentToRender.Component}
    </>
  )
}

export default NCCData
