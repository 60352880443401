import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import MainContext from '../Components/context/MainContext';
import PupilContext from '../Components/context/PupilContext';
import { routeUrl } from '../Components/utils/RouteUrl';

// This Hooks activate tab link with given path 
// Also checks if no student is selected than its return back to attendance page by backToDashboard paramter setting true
const useActivateTabLink = (pathname, backToDashboard) => {
  const history = useHistory();
  const {activePupilId} = useContext(PupilContext);
  const {state: mainContextState} = useContext(MainContext);
  
  useEffect(() => {  
    
    // Checks if backToDashboard is true then its return to dashboard there is no active pupil id selected
    if(backToDashboard) {
      !activePupilId && history.push(routeUrl.attendance);
    }
    // const navMenu = document.querySelector(".nav-menue");
    const navMenu = document.getElementById("navlinks");
    const navBar = document.querySelector(".nav-bar");
    const body = document.querySelector("body");

    // This add and removes navbar when its in mobile
    if (navBar && !navBar.classList.contains("hidden")) {
      navBar.classList.add("hidden");
      body && body.classList.remove("overflow-hidden");
    }

    // Activating Nav links for School portal
    { 
      mainContextState.reactSchoolAndCaller.includes(mainContextState.roleId) &&
        navMenu && navMenu.childNodes.forEach(a => {
          const li = a.childNodes[0];
          a.pathname === pathname
            ? li?.classList?.add("active")
            : li?.classList?.remove("active");
        })
    }

    // Activating Nav links for Pep Portal
    { 
      mainContextState.pep.includes(mainContextState.roleId) &&
        navMenu && navMenu.childNodes.forEach(li => {
          const a = li.childNodes[0];

          a.pathname == pathname
            ? a.classList.add("active")
            : a.classList.remove("active");
        })
    }

    mainContextState.ncc.includes(mainContextState.schoolId) &&
      navMenu && navMenu.childNodes.forEach(li => {
        const a = li.childNodes[0];

        a.pathname == pathname
          ? a.classList.add("active")
          : a.classList.remove("active");
      })

  }, [activePupilId]);
}

export default useActivateTabLink;
