import React, { memo, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton';
import { useDebouncedCallback } from 'use-debounce/lib';
import DatePicker from 'react-datepicker';
import moment, { months } from 'moment';
import { convertDateFromUkToUs } from '../../../../Components/utils/utilityFunctions';
import { Field } from 'formik';


const DataDateField = ({
  fieldName,
  dateChangeHandler,
  rest,
  readOnly
}) => {

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Field name={fieldName}>
      {
        ({field, form}) => {
          const selectedValue = (field.value && typeof field.value !== "object") ? new Date(convertDateFromUkToUs(field.value)) : null;
          return (
            <div className="relative flex items-center h-full">
              {
                readOnly ?
                  <DatePicker
                    selected={selectedValue}
                    onChange={(date) => dateChangeHandler({date, form, field, setIsLoading})}
                    dateFormat="dd/MM/yyyy"
                    className="pep-input"
                    showYearDropdown
                    dropdownMode="select"
                    {...rest}
                    readOnly
                  /> :
                  <DatePicker
                    selected={selectedValue}
                    onChange={(date) => dateChangeHandler({date, form, field, setIsLoading})}
                    dateFormat="dd/MM/yyyy"
                    className="pep-input"
                    showYearDropdown
                    dropdownMode="select"
                    {...rest}
                  /> 
              }
              {
                isLoading &&
                <div className="rounded-2xl px-3 absolute bg-white w-full h-full top-0 left-0 grid items-center">
                  <Skeleton count={1} />
                </div>
              }
            </div>
          )
         }
       }
    </Field>

  )
}

const isEqual = (prevState, nextState) => {
  if (prevState.value === nextState.value) {
    return true;
  } else {
    return false;
  }
}

// export default memo(DataDateField, isEqual);
export default DataDateField;
