import { FastField, Field } from 'formik';
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton';

const SelectField = ({ 
  onChangeHandler,
  saveForm,
  fieldName,
  optionsList, 
  rest,
  restForLoader
}) => {

  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className='relative'>
      <Field name={fieldName}>
        {
          ({form, field}) => {
            return (
              <select
                className='border-gray-300 border rounded-full p-2 text-sm w-full md:w-full'
                value={field.value ? field.value : ""}
                onChange={(e) => onChangeHandler({
                  fieldName,
                  setIsLoading,
                  fieldValue: e.target.value,
                  saveForm,
                  setFieldValue: form.setFieldValue,
                  values: form.values,
                  form,
                })}
                {...rest}
              >
                {optionsList}
              </select>
            )
          }
        }
      </Field>
      {
        isLoading &&
        <div className='absolute top-0 left-0 w-full h-full grid items-center bg-white' {...restForLoader}>
          <Skeleton />
        </div>
      }
    </div>
  )
}

export default SelectField
