import axios from 'axios'
import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import PupilContext from '../../../../Components/context/PupilContext'
import InputField from '../../../../Components/NCC/InputField'
import InputFieldAutoHeight from '../../../../Components/NCC/TargetsAndAction/InputFieldAutoHeight';
import NccDataHoc from '../../../../Components/NCC/NccDataHoc'
import RadioField from '../../../../Components/NCC/RadioField'
import SelectField from '../../../../Components/NCC/SelectField'
import TextAreaField from '../../../../Components/NCC/TextAreaField'
import { NCCReportApi } from '../../../../constants/apiUrls'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import DataTextField from '../../../Pep/PepData/components/DataTextField'
import AssessmentSubjectListModal from '../../../Assessment.js/components/AssessmentSubjectListModal'
import { useReducer } from 'react'
import nccDataPost16Reducer from '../../../../reducers/NCCData/nccDataPost16Reducer'
import { setLoading, setNccPost16Data, setUploadedSchoolReports } from '../../../../constants/reducerActions'
import NccDataPost16SubjectListModal from '../../../../Components/NCC/NccDataPost16SubjectListModal'
import DataDateField from '../../../Pep/PepData/components/DataDateField'
import NCCDataEyfsSkeleton from '../../../../Components/NCC/NCCDataEyfsSkeleton'
import UploadModal from '../../../Pep/PupilVoice/components/UploadModal'
import { checkForForTrimmedEmptyValue, formValidationWrapper, getArrayIndexFromString, getPrototypeName, getYearGroup, handleEmptyValuesForSaving, showErrorToast, showReportCompleteSuccess, showWarningToast } from '../../../../Components/utils/utilityFunctions'
import PepSecondaryHeader from '../../../Pep/PepSecondaryHeader'
import CompleteButton from '../../../../Components/NCC/CompleteButton'
import Swal from 'sweetalert2'
import IsCompletedContext from '../../../../Components/context/IsCompletedContext'
import DateField from '../../../../Components/NCC/DateField'
import NumberField from '../../../../Components/NCC/NumberField'

const progressOptionList = [
  <option value=""></option>,
  <option value="Below">Below</option>,
  <option value="Expected">Expected</option>,
  <option value="Exceeding">Exceeding</option>,
]

const primaryNeedOptionList = [
  <option value=""></option>,
  <option value="Specific Learning Difficulty">Specific Learning Difficulty</option>,
  <option value="Moderate Learning Difficulty">Moderate Learning Difficulty</option>,
  <option value="Severe Learning Difficulty">Severe Learning Difficulty</option>,
  <option value="Profound &amp; Multiple Learning Difficulty">Profound &amp; Multiple Learning Difficulty</option>,
  <option value="Social, Emotional and Mental Health">Social, Emotional and Mental Health</option>,
  <option value="Speech, Language and Communications Needs">Speech, Language and Communications Needs</option>,
  <option value="Hearing Impairment">Hearing Impairment</option>,
  <option value="Visual Impairment">Visual Impairment</option>,
  <option value="Multi-Sensory Impairment">Multi-Sensory Impairment</option>,
  <option value="Physical Disability">Physical Disability</option>,
  <option value="Autistic Spectrum Disorder">Autistic Spectrum Disorder</option>,
  <option value="Other Difficulty/Disability">Other Difficulty/Disability</option>,
]

const option1 = [
  "", "1-", "1", "1+", "2-", "2", "2+", "3-", "3", "3+", "4-", "4", "4+", "5-", "5", "5+",
  "6-", "6", "6+", "7-", "7", "7+", "8-", "8", "8+", "9-", "9", "U", "No GCSE", "Not Entered",
  "L2D*", "L2D", "L2M", "L2P", "L1D", "L1M", "L1P", "Pass", "9/9", "9/8", "8/8", "8/7",
  "7/7", "7/6", "6/6", "6/5", "5/5", "5/4", "4/4", "4/3", "3/3", "3/2", "2/2", "2/1",
  "1/1", "D*", "D", "M", "P", "Above Average", "Average", "Below Average",
  "Func Skill L1 Pass", "Func Skills L1 Fail", "Func Skills L2 Pass", "Func Skills L2 Fail",
  "Func Skills Entry L3 Pass", "Func Skills Entry L3 Fail", "ASDAN Pass", "P Scale 1",
  "P Scale 2", "P Scale 3", "P Scale 4", "Entry Level 1", "Entry Level 2", "Entry Level 3",
  "NC Year 1", "NC Year 2", "NC Year 3", "NC Year 4", "NC Year 5", "NC Year 6",
  "P1i", "P1ii", "P2i", "P2ii", "P3i", "P3ii", "P4", "P5", "P6", "P7", "P8",
  "P9", "P10", "P11", "P12", "P13", "P14", "P15", "P16", "P17", "P18", "Other"
]

const initialState = {
  dataPost16: null,
  ks4Subject: null,
  post16Ks5Grades: null,
  pupilUnique: null,
  subjectNames: null,
  englishGrade: null,
  mathsGrade: null,
  isLoading: false,
  schoolUploadedFiles: null,
  exclusionCount: null,
  presentPer: null,
}


const NCCDataPost16 = ({
  attendanceButtonHandler,
  exclusionsButtonHandler,
  // saveForm,
  onChangeHandler,
  numberOnChangeHandler,
  dateChangeHandler,
  downloadUploadedFile,
  deleteUploadedFile,
  exportPdf
}) => {
  const pdfReportName = "nccDataPost16";
  const fullPepPdfReportName = "NccFullPep";

  const schoolId = localStorage.getItem("schoolId");
  const userId = localStorage.getItem("userId");
  const roleId = localStorage.getItem("userRole");
  
  // Reducer For NCC POST 16 Data 
  const [state, dispatch] = useReducer(nccDataPost16Reducer, initialState);

  const { dateOfMeeting, pupilInfo } = useContext(PupilContext);

  // Is Completed context for setting sidebar
  const { setIsCompleted } = useContext(IsCompletedContext);
  
  const { subjectNames } = state;
  
  // this index hold the current clicked cell from subjectNames pop up list
  const [indexForKs4Subject, setIndexForKs4Subject] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [completeButtonLoader, setCompleteButtonLoader] = useState(false);
  
  useEffect(() => {
    const source = axios.CancelToken.source();
    if (dateOfMeeting && dateOfMeeting !== "No PEP" && !dateOfMeeting.includes("[")) {
      dispatch({ type: setLoading, payload: { isLoading: true } });

      const url = NCCReportApi.getPost16Data({
        schoolId,
        userId,
        roleId,
        dateOfMeeting,
        pupilId: pupilInfo.pupilId,
      })
      axios.get(url, { cancelToken: source.token })
        .then(res => {
          dispatch({ type: setNccPost16Data, payload: { data: res.data } })
          console.log(res.data, "response from ncc post 16 data");
        }).catch(err => {
          dispatch({ type: setLoading, payload: { isLoading: false } });
          showErrorToast("Problem while fetching data", { position: "bottom-right" })
        })
    } else {
      showWarningToast("Please select date of meeting for pupil.", { position: "bottom-right" })
    }
    

    return () => {
      source.cancel();
    }
  }, [dateOfMeeting])

  // This function open subject list pop up
  const openSubjectListPopup = (index) => {
    setIsOpen(true)
    setIndexForKs4Subject(index);
  };

  // This function close subject list pop up
  const closeSubjectListPopup = () => setIsOpen(false);

  // onchange handler for all assessment field except date field
  const onChangeHandlerForOtherSubjects = ({
    fieldName,
    fieldValue,
    setIsLoading,
    saveForm,
    setFieldValue,
    values
  }) => {
    try {
      const updatedValues = { ...values };
      
      const firstIndex = fieldName.split("[")[0]
      const secondIndex = fieldName.split("[")[1].split("]")[0]
      const thirdIndex = fieldName.split(".")[1]
      updatedValues[firstIndex][secondIndex][thirdIndex] = fieldValue;
      setFieldValue(fieldName, fieldValue);
      saveForm({
        setIsLoading,
        values: updatedValues,
      })
    } 
    catch(err) {
      
    }
    
  }

  const addRowToKs5 = (push) => {
    const object = {
      commentFromTutor: "",
      currentGrade: "",
      endOfYearTarget: "",
      nameOfQualification: "",
      parentId: "",
      parentrowNumber: "",
      pupilId: 10388,
      qualificationAndLevel: "",
      rowId: "",
      rowNumber: null,
      stringEndDate: "",
      stringStartDate: "",
    }
    push(object);
  }

  const deleteRowKs5 = ({ index, form }) => {
    const formValues = { ...form.values };
    const keyStage5ResultList = formValues.keyStage5ResultList.filter((item, i) => i !== index)
    formValues.keyStage5ResultList = keyStage5ResultList;
    form.setFieldValue("keyStage5ResultList", keyStage5ResultList);
    saveForm({
      values: formValues,
      setIsLoading: () => { },
    })
  } 

  const createOptionsList = option1.map((item, index) => <option value={item} key={index}>{item}</option>)

  const uploadedDocumentListHandler = (data, form) => {
    form.setFieldValue("schoolUploadedFiles", data)
    // dispatch({ type: setUploadedSchoolReports, payload: { schoolReports: data } })
  }

  const saveForm = ({ setIsLoading, values }) => {
    const postUrl = NCCReportApi.savePost16Data;
    const formData = new FormData();
    let objectName = "";
    if (getYearGroup(pupilInfo.classYear) === "eyfs") { objectName = "dataEyfs" }
    if (getYearGroup(pupilInfo.classYear) === "primary") { objectName = "dataPrimary" }
    if (getYearGroup(pupilInfo.classYear) === "secondary") { objectName = "dataSecondary" }
    if (getYearGroup(pupilInfo.classYear) === "post16") { objectName = "dataPost16" }

    Object.entries(values[objectName]).forEach(([key, value]) => {
      const valueType = getPrototypeName(value);
      if (valueType === "Object") {
        Object.entries(value).forEach(([key2, value2]) => {
          formData.append(`${objectName}.${key}.${key2}`, handleEmptyValuesForSaving(value2));
          // formData.append(`${objectName}.${key}.${key2}`, value2 ? value2 : "");
        })
      } else if(valueType === "Array") {
        value.forEach((item, i) => {
          Object.entries(item).forEach(([k, v]) => {
            formData.append(`${objectName}.${key}[${i}].${k}`, handleEmptyValuesForSaving(v))
          })
        })
      } else if (valueType !== "Object" && valueType !== "Array") {
        formData.append(`${objectName}.${key}`, handleEmptyValuesForSaving(value))
        // formData.append(`${objectName}.${key}`, value ? value : "")
      }
    })

    values["ks4Subject"].forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        formData.append(`ks4Subject[${index}].${key}`, handleEmptyValuesForSaving(value));
      })
    })

    values["post16Ks5Grades"].forEach((item, index) => {
      Object.entries(item).forEach(([key, value]) => {
        formData.append(`post16Ks5Grades[${index}].${key}`, handleEmptyValuesForSaving(value));
      })
    })
    // ks4Subject
    // post16Ks5Grades
    formData.append("englishGrade", handleEmptyValuesForSaving(values.englishGrade));
    formData.append("mathsGrade", handleEmptyValuesForSaving(values.mathsGrade));
    
    axios.post(postUrl, formData)
      .then(res => {
        console.log(res.data, "saving response form post 16 data");
        setIsLoading(false);
      })
      .catch(err => {
        showErrorToast("Problem while saving");
        setIsLoading(false);
      }) 
    setIsLoading(true);

  }

  const onChangeHandlerKs5 = ({
    fieldName,
    fieldValue,
    saveForm,
    values,
    setIsLoading,
    setFieldValue
  }) => {
    setFieldValue(fieldName, fieldValue);
    const updatedValues = { ...values };
    const splittedFieldName = fieldName.split(".");
    if(splittedFieldName.length === 2) {
      const objectName = splittedFieldName[0].split("[")[0];
      const rowIndex = getArrayIndexFromString(splittedFieldName[0]);
      const objectFieldName = splittedFieldName[1];
      updatedValues[objectName][rowIndex][objectFieldName] = fieldValue;
// dataPost16.qualificationsList[${index}].currentGrade
    } else if (splittedFieldName.length === 3) {
      const objectName = splittedFieldName[0];
      const secondOjectName = splittedFieldName[1].split("[")[0]
      const rowIndex = getArrayIndexFromString(splittedFieldName[1]);
      const objectFieldName = splittedFieldName[2];
      try {
        updatedValues[objectName][secondOjectName][rowIndex][objectFieldName] = fieldValue;
      } catch (e) {
        console.log(e);
        updatedValues[objectName][secondOjectName][rowIndex] = {};
        updatedValues[objectName][secondOjectName][rowIndex][objectFieldName] = fieldValue;
        // updatedValues[objectName] = ""
      }
    }

    saveForm({
      values: updatedValues,
      setIsLoading,
    })
  }

  const onChangeKs4Subject = ({
    fieldName,
    fieldValue,
    setIsLoading,
    saveForm,
    setFieldValue,
    values
  }) => {
    setFieldValue(fieldName, fieldValue);
    setTimeout(() => {
      console.log();
    })
  } 

  const initialFormState = {
    dataPost16: state.dataPost16,
    ks4Subject: state.ks4Subject,
    post16Ks5Grades: state.post16Ks5Grades,
    pupilUnique: state.pupilUnique,
    subjectNames: state.subjectNames,
    englishGrade: state.englishGrade,
    mathsGrade: state.mathsGrade,
    schoolUploadedFiles: state.schoolUploadedFiles,
    exclusionCount: state.exclusionCount,
    presentPer: state.presentPer,
    
  }

  const isCompletedCallback = () => {
    setIsCompleted("dataIsCompleted", true);
    showReportCompleteSuccess("Data Post 16 Completed");
  }

  const successCallback = (values) => {
    values.dataPost16.isCompleted = 1;
  }

  const checkForErrors = (values) => {
    let error = "";

    if (checkForForTrimmedEmptyValue(values.dataPost16.commonData.youngPersonHaveSEND)) {
      error = "'Does this young person have SEND?' is required ";
    }

    else if (checkForForTrimmedEmptyValue(values.presentPer)) {
      error = "Attendance is required";
    }

    else if (checkForForTrimmedEmptyValue(values.dataPost16.makingGoodProgress)) {
      error = "'From your assessments, is the child/young person making good progress?' is required";
    }
    else if (checkForForTrimmedEmptyValue(values.dataPost16.sdqDate)) {
      error = "'SDQ Date' is required";
    }

    return error;
  }

  const formValidaton = formValidationWrapper({
    saveForm,
    setIsCompleted,
    attributeName: "dataIsCompleted",
    successCallback,
    checkForErrors,
    setCompleteButtonLoader,
    saveCallback: isCompletedCallback,
  });

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize
      onSubmit={formValidaton}
    >
      <Form>
        <PepSecondaryHeader
          heading={`NCC PEP - Data Post-16`}
          buttons={[
            <CompleteButton
              fieldName={"dataSecondary.isCompleted"}
              completeButtonLoader={completeButtonLoader}
            />,
            <div class="export-btn">
              <span className="blue-button px-5 py-2  text-sm">Export</span>
              <ul>
                <li>
                  <button type="button" onClick={() => exportPdf(fullPepPdfReportName)}>Full Pep</button>
                </li>
                <li>
                  <button type="button" onClick={() => exportPdf(pdfReportName)}>PDF</button>
                </li>
              </ul>
            </div>
          ]}
        />
        {
          state.isLoading ?
            <NCCDataEyfsSkeleton /> :
            <div className="secondary-section w-full">

              {/* First part */}
              <div className="rounded-3xl bg-white lg:w-max md:pr-8 overflow-hidden">
                <div className="flex flex-col gap-2 md:flex-row md:gap-3 md:items-center">
                  <label htmlFor="" className='p-6 blue md:w-96'>Does this young person have SEND? <span className='required'>*</span></label>
                  <div className='pl-4 pt-2 pb-4 md:pl-0 md:pb-0'>
                    <RadioField
                      fieldName="dataPost16.commonData.youngPersonHaveSEND"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                      radioOptionsList={[
                        { innerValue: "Yes", outerValue: "Yes" },
                        { innerValue: "No", outerValue: "No" },
                      ]}
                    />
                  </div>
                </div>

                <div className="divide-solid divide-class ml-2 w-full"></div>

                <div className="flex flex-col gap-2 md:flex-row md:gap-3">
                  <label htmlFor="" className='p-6 pb-12 blue md:w-96'>If yes, how are they being supported? <span className='required'>*</span></label>
                  <div className='flex flex-col gap-3 pl-4 pb-4 md:pl-0 md:pb-0'>
                    <div className="flex items-center gap-3 md:pt-6">
                      <label htmlFor="" className='w-28'>SEN Support</label>
                      <Field name="dataPost16.commonData.youngPersonHaveSEND">
                        {
                          ({form, field}) => {
                            
                            return (
                              <RadioField
                                fieldName="dataPost16.commonData.SENSupport"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      
                    </div>
                    <div className="flex items-center gap-3">
                      <label htmlFor="" className='w-28'>EHCP</label>
                      <Field name="dataPost16.commonData.youngPersonHaveSEND">
                        {
                          ({form, field}) => {
                            
                            return (
                              <RadioField
                                fieldName="dataPost16.commonData.EHCP"
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                radioOptionsList={[
                                  { innerValue: "Yes", outerValue: "Yes" },
                                  { innerValue: "No", outerValue: "No" },
                                ]}
                                disabled={field.value === "No" ? true : false}
                              />
                            )
                          }
                        }
                      </Field>
                      
                    </div>
                  </div>
                </div>
              </div>

              {/* Second Part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Attendance</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex gap-2 items-center">
                      <label htmlFor="">Attendance %: <span className="required">*</span> </label>
                      <InputField
                        fieldName="presentPer"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button
                        type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={attendanceButtonHandler}
                      >Enter Attendance</button>

                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Attendance</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If below 95%, what actions are being taken or planned?</p>
                    <TextAreaField
                      fieldName="dataPost16.commonData.actionsBeingTaken"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-3">
                  <h1 className='text-xl font-semibold'>Exclusions</h1>
                  <div className='flex flex-col gap-3 md:gap-4 md:flex-row md:items-center'>
                    <div className="flex items-center gap-3">
                      <label htmlFor="">No of seassions: </label>
                      <InputField
                        fieldName="exclusionCount"
                        saveForm={saveForm}
                        onChangeHandler={numberOnChangeHandler}
                        rest={{
                          className: "px-2 py-1 radio rounded-full border-gray-300 border text-sm w-16",
                          readOnly: true,
                        }}
                      />
                    </div>
                    <div className='flex gap-3'>
                      <button type='button'
                        className='blue-button py-2 px-4 text-sm'
                        onClick={exclusionsButtonHandler}
                      >Enter Exculsions</button>
                      {/* <button type='button' className='blue-button py-2 px-4 text-sm'>Recalculate Exclusions</button> */}
                    </div>
                  </div>
                  <div>
                    <p className='font-medium'>If our young person has had an exclusion in the last term, what actions are in place to prevent further exclusions?</p>
                    <TextAreaField
                      fieldName="dataPost16.commonData.actionsAreInPlace"
                      onChangeHandler={onChangeHandler}
                      saveForm={saveForm}
                    />
                  </div>
                </div>
              </div>

              {/* thirda part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">
                <h2 className='font-medium'>If YES please answer the following:</h2>
                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label htmlFor="">If Yes for ECHP, what is the primary identified need?</label>
                  <SelectField
                    fieldName={"dataPost16.commonData.primaryIdentifiedNeed"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={primaryNeedOptionList}
                  />
                </div>
              </div>

              {/* Forth Part */}
              <div className="rounded-3xl bg-white p-4 w-full mt-4">
                <div className="flex w-full items-center">
                  <label className="pr-4">Is our young person registered disabled?</label>
                  
                  <SelectField
                    fieldName={"dataPost16.registeredDisabled"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "pep-select "
                    }}
                  />
                </div>

                <div className="table-outer rounded-3xl bg-white mt-2">
                  <div className="table-box overflow-x-auto">
                    <table class="table-auto table-text w-full">
                      <tbody>
                        <tr>
                          <td className="blue" width={"50%"}>
                            <div className="flex gap-4 items-center">
                              <p>Employability status: </p>  
                              <SelectField 
                                fieldName={"dataPost16.employabilityStatus"}
                                onChangeHandler={onChangeHandler}
                                saveForm={saveForm}
                                optionsList={primaryNeedOptionList}
                                optionsList={[
                                  <option value=""></option>,
                                  <option value="EET">EET</option>,
                                  <option value="NEET">NEET</option>,
                                ]}
                                rest={{
                                  className: "pep-select"
                                }}
                              />
                            </div>
                          </td>
                          <td className="d-blue" width={"50%"}>
                            Employability status overview e.g. studying A level full time at 6th form, working part time, full time apprenticeship:
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <TextAreaField
                              fieldName="dataPost16.employabilityStatus1"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={3}
                            />
                          </td>
                          <td>
                            <TextAreaField
                              fieldName="dataPost16.employabilityStatus2"
                              onChangeHandler={onChangeHandler}
                              saveForm={saveForm}
                              rows={3}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Key stage 4 */}
                <div className="pink mt-4 mb-1 rounded-3xl px-4 py-2 flex w-full items-center">
                  <h3><b>Key stage 4 Grades</b></h3>
                </div>
                <div className="table-outer rounded-3xl bg-white">
                  <div className="table-box overflow-x-auto">
                    <table className="table-auto table-text w-full assessment-table">
                      <thead>
                        <tr className="blue">
                          <th className="w-1/5 ">Subject Name</th>
                          <th className="w-1/5 ">Result</th>
                          <th className="w-1/5 ">Subject Name</th>
                          <th className="w-1/5 ">Result</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state.isLoading ?
                            <>
                              <tr>
                                <td colSpan={4}><Skeleton height={30} /></td>
                              </tr>
                              <tr>
                                <td colSpan={4}><Skeleton height={30} /></td>
                              </tr>
                              <tr>
                                <td colSpan={4}><Skeleton height={30} /></td>
                              </tr>
                              <tr>
                                <td colSpan={4}><Skeleton height={30} /></td>
                              </tr>
                              <tr>
                                <td colSpan={4}><Skeleton height={30} /></td>
                              </tr>
                            </> :
                            <>
                              <tr>
                                <td className="">English</td>
                                <td className="">
                                  <SelectField
                                    fieldName="englishGrade"
                                    onChangeHandler={onChangeHandler}
                                    optionsList={createOptionsList}
                                    saveForm={saveForm}
                                  />
                                </td>
                                <td className="">Maths</td>
                                <td className="">
                                  <SelectField
                                    fieldName="mathsGrade"
                                    onChangeHandler={onChangeHandler}
                                    optionsList={createOptionsList}
                                    saveForm={saveForm}
                                  />
                                </td>
                              </tr>
                              <FieldArray name="ks4Subjects">
                                {
                                  ({ form }) => {
                                    const ks4Subjects = form.values.ks4Subject;
                                    return (
                                      (ks4Subjects && ks4Subjects.length > 0) ? 
                                        ks4Subjects  
                                        .filter((item) => {
                                          return item.subIndex % 2 !== 0
                                        })
                                        .map((item, index) => {
                                          const subIndex = item.subIndex;
                                          if (item.subIndex % 2 !== 0) {
                                            return (
                                              <tr key={item.subIndex}>
                                                <td className="" onClick={() => openSubjectListPopup(subIndex - 1)}>
                                                  {ks4Subjects[subIndex - 1]?.subject}
                                                </td>
                                                <td className="">
                                                  <SelectField
                                                    fieldName={`ks4Subject[${subIndex - 1}].grade`}
                                                    onChangeHandler={onChangeHandlerForOtherSubjects}
                                                    optionsList={createOptionsList}
                                                    saveForm={saveForm}
                                                  />
                                                </td>
                                                <td onClick={() => openSubjectListPopup(subIndex)}> {ks4Subjects[subIndex]?.subject}</td>
                                                <td>
                                                  <SelectField
                                                    fieldName={`ks4Subject[${subIndex}].grade`}
                                                    onChangeHandler={onChangeHandlerForOtherSubjects}
                                                    optionsList={createOptionsList}
                                                    saveForm={saveForm}
                                                  />
                                                </td>
                                              </tr>
                                            )
                                          }
                                        }) : null
                                    )
                                  }
                                }
                              </FieldArray>
                            </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

                {/* Key stage 5 */}
                <div className="pink mt-5 mb-1 rounded-3xl px-4 py-2 flex w-full items-center">
                  <h3><b>Key stage 5 Grades</b></h3>
                </div>
                <div className="table-outer rounded-3xl bg-white pt-1">
                  <div className="custom-table">
                    <table className="text-center w-full assessment-table">
                      <thead>
                        <tr className="blue">
                          <th className="text-center" style={{ width: "20%" }}>Name Of Qualification</th>
                          <th className="" style={{ width: "20%" }}>Qualification & Level</th>
                          <th className="" style={{ width: "10%" }}>Start Date</th>
                          <th className="" style={{ width: "10%" }}>End Date</th>
                          <th className="" style={{ width: "10%" }}>Current Grade</th>
                          <th className="" style={{ width: "10%" }}>End of the year target</th>
                          <th className="" style={{ width: "20%" }}>Comments from Tutors</th>
                          <th className="" style={{ width: "5%" }}></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          state.isLoading ?
                            <>
                              <tr>
                                <td colSpan={5}>
                                  <Skeleton height={30} />
                                </td>
                              </tr>
                            </> :
                            <FieldArray name="post16Ks5Grades">
                              {
                                ({ form }) => {
                                  return (
                                    form.values.post16Ks5Grades && form.values.post16Ks5Grades.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td className='relative'>
                                            <InputFieldAutoHeight
                                              fieldName={`post16Ks5Grades[${index}].nameOfQualification`}
                                              saveForm={saveForm}
                                              onChangeHandler={onChangeHandlerKs5}
                                              restForLoader={{
                                                className: "bg-white absolute top-0 left-0 w-full h-full px-1 grid items-center",
                                              }}
                                            />
                                          </td>
                                          <td className='relative'>
                                            <InputFieldAutoHeight
                                              fieldName={`post16Ks5Grades[${index}].qualificationAndLevel`}
                                              saveForm={saveForm}
                                              onChangeHandler={onChangeHandlerKs5}
                                              restForLoader={{
                                                className: "bg-white absolute top-0 left-0 w-full h-full px-1 grid items-center",
                                              }}
                                            />
                                          </td>
                                          <td className='relative'>
                                            <DateField
                                              dateChangeHandler={onChangeHandlerKs5}
                                              fieldName={`post16Ks5Grades[${index}].startDate`}
                                              saveForm={saveForm}
                                              rest={{
                                                className: "w-full pep-input"
                                              }}
                                            
                                            />
                                          </td>
                                          <td>
                                            <DateField
                                              dateChangeHandler={onChangeHandlerKs5}
                                              fieldName={`post16Ks5Grades[${index}].endDate`}
                                              saveForm={saveForm}
                                              rest={{
                                                className: "w-full pep-input"
                                              }}
                                            />
                                          </td>
                                          <td className='relative'>
                                            <InputFieldAutoHeight
                                              fieldName={`dataPost16.qualificationsList[${index}].currentGrade`}
                                              saveForm={saveForm}
                                              onChangeHandler={onChangeHandlerKs5}
                                              restForLoader={{
                                                className: "bg-white absolute top-0 left-0 w-full h-full px-1 grid items-center",
                                              }}
                                            />
                                          </td>
                                          <td className='relative'>
                                            <InputFieldAutoHeight
                                              fieldName={`post16Ks5Grades[${index}].endOfYearTarget`}
                                              onChangeHandler={onChangeHandlerKs5}
                                              saveForm={saveForm}
                                              restForLoader={{
                                                className: "bg-white absolute top-0 left-0 w-full h-full px-1 grid items-center",
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <TextAreaField
                                              fieldName={`dataPost16.qualificationsList[${index}].commentFromTutor`}
                                              onChangeHandler={onChangeHandlerKs5}
                                              saveForm={saveForm}
                                              rest={{
                                                className: "w-full pep-input",
                                              }}
                                              rows={2}
                                            />
                                          </td>
                                          <td>
                                            {
                                              (index > 2) &&
                                              <Field>
                                                {
                                                  ({ form }) => {
                                                    return (
                                                      <i
                                                        className="fa-solid fa-circle-trash text-red-500 text-lg cursor-pointer"
                                                        onClick={() => deleteRowKs5({ index, form })}
                                                      ></i>
                                                    )
                                                  }
                                                }
                                              </Field>
                                            }
                                          </td>
                                        </tr>
                                      )
                                    })
                                  )
                                }
                              }
                            </FieldArray>
                        }
                      </tbody>
                    </table>

                    {/* Add button for ks5 gades table */}
                    <FieldArray name="post16Ks5Grades">
                      {
                        ({ push }) => {
                          return (
                            <button
                              type='button'
                              onClick={() => addRowToKs5(push)}
                              className="blue-button px-5 py-2 mt-2 text-sm"
                            >
                              Add+
                            </button>
                          )
                        }
                      }
                    </FieldArray>
                  </div>
                </div>
              </div>

              {/* Fifth Part */}
              <div className="rounded-3xl bg-white w-full p-6 mt-4 flex flex-col gap-5">

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    Is our child/young person on a bespoke, targeted curriculum which is not measured by age/phase or working towards qualifications?
                  </label>
                  <SelectField
                    fieldName={"dataPost16.targetedCurriculum"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div className="table-box overflow-x-auto">
                  <table className="table-auto w-full">
                    <thead>
                      <tr className=''>
                        <th className='blue border-collapse border-2 border-white'>Current pathway/curriculum/subject</th>
                        <th className='d-blue border-collapse border-2 border-white'>Currently working at</th>
                        <th className='purple border-collapse border-2 border-white'>Expectations/target for end of KS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <TextAreaField
                            fieldName="dataPost16.currentPathway"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />
                        </td>
                        <td className='text-center'>
                          <TextAreaField
                            fieldName="dataPost16.currentWorking"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />

                        </td>
                        <td className='text-center'>
                          <TextAreaField
                            fieldName="dataPost16.expectationsTarget"
                            onChangeHandler={onChangeHandler}
                            saveForm={saveForm}
                          />

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className='flex flex-col gap-2 md:gap-4 md:flex-row md:items-center'>
                  <label className='font-medium'>
                    From your assessments, is the child/young person making good progress? <span className="required">*</span>
                  </label>
                  <SelectField
                    fieldName={"dataPost16.makingGoodProgress"}
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                    optionsList={[
                      <option value=""></option>,
                      <option value="Yes">Yes</option>,
                      <option value="No">No</option>,
                    ]}
                    rest={{
                      className: "border-gray-300 border rounded-full py-1 px-4 text-sm"
                    }}
                  />
                </div>

                <div>
                  <p className='font-medium'>How do you know and how it is measured?</p>
                  <TextAreaField
                    fieldName="dataPost16.howItIsMeasured"
                    onChangeHandler={onChangeHandler}
                    saveForm={saveForm}
                  />
                </div>

                <div className="rounded-3xl bg-white w-full mt-4" >
                  <div className="flex flex-col gap-5">
                    <h1 className='font-medium text-md'>SDQ Summary</h1>
                    <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-8">
                      <div className="flex items-center gap-2">
                        <label htmlFor="" className=''>SDQ Score</label>
                        <NumberField
                          fieldName="dataPost16.sdqScore"
                          saveForm={saveForm}
                          onChangeHandler={numberOnChangeHandler}
                          rest={{
                            className: "py-1 px-2 rounded-full border-gray-300 border text-sm w-16",
                          }}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <label className="">SDQ Date <span className="required">*</span></label>
                        <DateField
                          dateChangeHandler={dateChangeHandler}
                          fieldName="dataPost16.sdqDate"
                          saveForm={saveForm}
                        />
                        {/* <DateField /> */}
                      </div>
                    </div>
                    <p>Social Worker to provide the overall SDQ score. Discussion should take place about what impact this may have in school.</p>
                    <div className='flex flex-col gap-5 md:flex-row md:gap-7 md:items-center'>
                      <div className="flex gap-3 items-center">
                        <label className='font-medium'>School Report Uploaded? </label>
                        <RadioField
                          fieldName="dataPost16.schoolReportUploaded"
                          onChangeHandler={onChangeHandler}
                          saveForm={saveForm}
                          radioOptionsList={[
                            { innerValue: "Yes", outerValue: "Yes" },
                            { innerValue: "No", outerValue: "No" },
                          ]}
                        />
                      </div>

                      <div className='flex  items-center'>
                        <div class='file file--upload'>
                          <button type="button" 
                            className="upload-button px-5 py-2 text-sm"
                            onClick={() => setUploadModalOpen(true)}
                          >
                            Upload<i class="upload-icon fa-regular fa-cloud-arrow-up"></i>
                          </button>
                        </div>
                        <div className="pl-4 view-report">
                          <button type="button" className="btn pep-btn">View Reports <i class="fa-regular fa-angle-down"></i></button>
                          <ul className="scroll_add">
                            <FieldArray name="schoolUploadedFiles">
                              {
                                ({ form, remove }) => {
                                  return (
                                    form.values.schoolUploadedFiles && form.values.schoolUploadedFiles.map((result, index) => {
                                      return (
                                        <li key={index} className="flex justify-between items-center gap-3">
                                          <span
                                            onClick={() => downloadUploadedFile({
                                              blobKey: result.blobkey,
                                              fileName: result.fileName,
                                            })}
                                            className='flex justify-between items-center gap-3'>
                                           {result.fileShowName ? result.fileShowName : result.fileName}
                                          </span>
                                          <i
                                            className="fa-solid fa-circle-trash text-red-600"
                                            onClick={() => deleteUploadedFile({
                                              blobKey: result.blobkey,
                                              fileName: result.fileName,
                                              deleteFile: () => remove(index),
                                            })}
                                          >
                                          </i>
                                        </li>
                                      )
                                    })
                                  )
                                }
                              }
                            </FieldArray>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
        }
        <NccDataPost16SubjectListModal
          isOpen={isOpen}
          closeModal={closeSubjectListPopup}
          subjectList={subjectNames}
          indexForKs4Subject={indexForKs4Subject}
          onChangeHandler={onChangeHandlerForOtherSubjects}
          saveField={saveForm}
        />
        <Field>
          {
            ({form}) => {
              return (
                <UploadModal
                  isOpen={uploadModalOpen}
                  closeModal={() => setUploadModalOpen(false)}
                  isSecondary="Y"
                  listHandler={uploadedDocumentListHandler}
                  uploadUrl={NCCReportApi.saveSchoolReport}
                  responseObjectName={"schoolReport"}
                  form={form}
                />
              )
            }
          }
        </Field>
      </Form>
    </Formik>
  )
}

export default NccDataHoc(NCCDataPost16);
