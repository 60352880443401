export const routeUrl = {
    // Urls for pupil
    login_url: "/login",
    dashboard_url: "/dashboard",
    contact_us: "/contact-us",
    attendance: "/attendance",
    exclusions: "/exclusions",
    extraction: "/data-extraction-status",
   
    ks5: "/ks5",
    pupil: "/pupil",
    contacts: "/contacts",
    call: "/calling",
    training: "/training",
    privacyAndPolicy: "/privacy-policy",
    termsAndConditions: "/termsandconditions",
    compliance: "/compliance",
    pep: "/pep",
    changePassword: "/changepassword",
    
    pep_status: "/pep-status",
    target: "/targets",
    finance: "/finance",
    doc: "/doc",
    send: "/send",
    meeting: "/meeting",
    start_new_pep: "/start-new-pep",
    pupil_voice: "/pupil-voice",
  
    basic_info: "/basic-info",
    basic_info_doc: "/basic-info/doc",
    basic_info_attendance: "/basic-info/attendance",
    basic_info_prior_attainment: "/basic-info/priorattainment",
    basic_info_pupil: "/basic-info/pupil",
    basic_info_contacts: "/basic-info/contacts",
    basic_info_pep_alt_ed_neet: "/basic-info/alt-ed-neet",
    basic_info_pep_intervention: "/basic-info/intervention",
    basic_info_case_notes: "/basic-info/case-notes",
    basic_info_uasc: "/basic-info/uasc",
    basic_info_add_new_school: "/basic-info/addnewschool",
    basic_info_exclusions: "/basic-info/exclusions",
  
    personal_info: "/personal-info",
    personal_info_doc: "/personal-info/doc",
    personal_info_attendance: "/personal-info/attendance",
    personal_info_prior_attainment: "/personal-info/priorattainment",
    personal_info_pupil: "/personal-info/pupil",
    personal_info_contacts: "/personal-info/contacts",
    personal_info_pep_alt_ed_neet: "/personal-info/alt-ed-neet",
    personal_info_pep_intervention: "/personal-info/intervention",
    personal_info_case_notes: "/personal-info/case-notes",
    personal_info_uasc: "/personal-info/uasc",
    personal_info_add_new_school: "/personal-info/addnewschool",
    personal_info_exclusions: "/personal-info/exclusions",
  
    pep_dashboard: "/pep_dashboard",
    pep_data: "/data",
    // "pep_14_to_16": "/14-19",
    pep_14_to_16_Y9: "/14-19-Y9",
    pep_14_to_16_Y10: "/14-19-Y10",
    pep_14_to_16_Y11: "/14-19-Y11",
    pep_cin_cp: "/pep_cin_cp",
    pep_16_to_19: "/16-19",
    assessment: "/assessment",
  
    // Pep Header Filter Url
    pep_is_due: "/pep-is-due",
    pep_is_overdue: "/pep-is-overdue",
    pep_rejected: "/pep-rejected",
    pep_inadequate: "/pep-inadequate",
    pep_approved: "/pep-approved",
    pep_approved_with_action: "/pep-approved-with-action",
    pep_not_approved: "/pep-not-approved",
    pep_status_filter: "/pep-status-filter",
    all_peps: "/all-peps",
  
    // NCC Reports Url
    ncc_data: "/ncc-data",
    ncc_pupil_voice: "/ncc-pupil-voice",
    ncc_meeting: "/ncc-meeting",
    ncc_strength: "/ncc-strength",
    ncc_targets: "/ncc-targets",
  
    // Dashboard reports
    analysis: "/analysis",
    attendanceCaller: "/attendance-caller",
    dashboard: "/dashboard",
    reports: "/reports",
    summary:'/Summary',
  };
  