import React from 'react';
import { settings } from '../Components/utils/Settings';

export const pupilVoiceApi = {

  getPrimaryPupilVoice: (pupilId, dateOfPep, schoolId) => `${settings.API_HOST}/rest/primaryVoice?pupilId=${pupilId}&dateOfMeeting=${dateOfPep}&schoolId=${schoolId}`,

  postPrimaryPupilVoiceTextArea: `${settings.API_HOST}/rest/savePrimaryVoiceModule`,

  postAchievementItem: `${settings.API_HOST}/rest/savePrimaryVoiceModuleAchievements`,

  postSecondaryAchievementItem : `${settings.API_HOST}/rest/saveSecondaryVoiceModuleAchievements`,

  postPost16AchievementItem : `${settings.API_HOST}/rest/savePost16VoiceModuleAchievements`,
  
  generateAchievementRow: `${settings.API_HOST}/rest/GenerateAchievementRow`,

  generateSecondaryVoiceAchievementRow: `${settings.API_HOST}/rest/GenerateSecondaryVoiceAchievementRow`,

  generatePost16VoiceAchievementRow: `${settings.API_HOST}/rest/GeneratePost16VoiceAchievementRow`,

  generateEyfsVoiceAchievementRow: `${settings.API_HOST}/rest/GenerateEarlyYearVoiceAchievementRow`,

  deleteAchievementItem: `${settings.API_HOST}/rest/deletePrimaryAchievementRow`,

  deleteSecondaryAchievementItem: `${settings.API_HOST}/rest/deleteSecondaryAchievementRow`,
  
  deletePost16AchievementItem: `${settings.API_HOST}/rest/deletePost16AchievementRow`,

  deleteEyfsAchievementItem: `${settings.API_HOST}/rest/deleteEarlyYearVoiceAchievementRow`,

  uploadPrimaryVoiceFile: `${settings.API_HOST}/rest/uplaodPrimaryVoiceFile`,

  uploadSecondaryVoiceFile: `${settings.API_HOST}/rest/uplaodSecondaryVoiceFile`,

  uploadPost16VoiceFile: `${settings.API_HOST}/rest/uplaodSecondaryVoiceFile`,

  uploadEarlyYearVoiceFile: `${settings.API_HOST}/rest/uplaodSecondaryVoiceFile`,

  uploadDataModuleFile: `${settings.API_HOST}/rest/uplaodSchoolReportFile`,

  // deletePrimaryVoiceFile: `${settings.API_HOST}/rest/deleteFiles`,

  deletePrimaryVoiceFile: `${settings.API_HOST}/rest/deleteDoc`,
  
  downlaodUrl: (blobKey, fileName) => `${settings.API_HOST}/rest/serveVideofile?blobkey=${blobKey}&filename=${fileName}`,

  getSecondaryVoice: (activePupilId, dateOfMeeting , schoolId) => `${settings.API_HOST}/rest/secondaryVoice?pupilId=${activePupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}`,

  getEarlyYearVoice: (activePupilId, dateOfMeeting , schoolId) => `${settings.API_HOST}/rest/earlyYearVoice?pupilId=${activePupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}`,

  postEarlyVoice: `${settings.API_HOST}/rest/saveEarlyYearVoiceModule`,

  postEarlyVoiceAchievementItem: `${settings.API_HOST}/rest/saveEarlyYearVoiceAchievements`,
}

export const dataApi = {
  savePost16Data: `${settings.API_HOST}/rest/saveEarlyYearVoiceModule`,
}

export const pupilTargetApi = {
  getPupilTargetApi: (activePupilId, dateOfMeeting, schoolId) => `${settings.API_HOST}/rest/target?pupilId=${activePupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}`,

  createDynamicRow: `${settings.API_HOST}/rest/CreateReviewDynamicRows`,

  saveTargetModule: `${settings.API_HOST}/rest/SaveTargetModule`,

  deleteDynamicRow: `${settings.API_HOST}/rest/DeleteDynamicRow`,

  createSmartTargetDynamicRow: `${settings.API_HOST}/rest/CreateSmartTargetDynamicRows`,

  generateRefrenceNumberGenerator: `${settings.API_HOST}/rest/GenerateRefrenceNumber`,

}

export const pupilFinanceApi = {
  getFinanceReport: ({ 
    userId, 
    agreedFilter,
    roleId, 
    page, 
    recordPerPage,
  }) => `${settings.API_HOST}/rest/fetchFinance?finance.userId=${userId}&finance.agreedFilter=${agreedFilter}&finance.roleId=${roleId}&finance.page=${page}&finance.recordPerPage=${recordPerPage}`,

  exportFinanceExcel: ({ userId, agreedFilter }) => `${settings.API_HOST}/rest/ExportFinanceExcel?finance.userId=${userId}&finance.agreedFilter=${agreedFilter}`,
}

export const startNewPepApi = {
  fetchStartNewPep : ({pupilId, schoolId, roleId, userId, dateOfMeeting}) => {
    return (`${settings.API_HOST}/rest/startNew?pupilId=${pupilId}&schoolId=${schoolId}&roleId=${roleId}&userId=${userId}&dateofmeeting=${dateOfMeeting}`)
  },

  calculateTermOfPep: ({ dateOfMeeting }) => `${settings.API_HOST}/rest/CalculateTerm?dateofmeeting=${dateOfMeeting}`,

  saveStartNewPep: `${settings.API_HOST}/rest/savePepStartNew`,
}

// Api for send report for pep
export const sendReportApi = {
  saveSendModule: `${settings.API_HOST}/rest/saveSendModule`,
}

// Api for Primary Data Report
export const primaryDataReportApi = {
  savePrimaryData: `${settings.API_HOST}/rest/savePrimaryModule`,
}

// Api for Assessment
export const assessmentReportApi = {
  getAssessmentReport: ({ pupilId, schoolId, userId, termOfPep, classYear }) => `${settings.API_HOST}/rest/fetchAssessmentData?pupilId=${pupilId}&schoolId=${schoolId}&userId=${userId}&termofpep=${termOfPep}&classYear=${classYear}`,

  saveReportForY1: `${settings.API_HOST}/rest/saveAssessmentData`,

  exportAssessmentReport: ({ pupilId, schoolId, userId, termOfPep, classYear }) => `${settings.API_HOST}/rest/PDFReports?reportName=${"Assessment"}&pupilId=${pupilId}&userId=${userId}&schoolId=${schoolId}&termofpep=${termOfPep}&classYear=${classYear}`,

}

export const pepCinCpReportApi = {
  getCinCpReport: (activePupilId) => {
    return `${settings.API_HOST}/rest/cin-cp?pupilId=${activePupilId}`;
  },

  postCinCpReport: `${settings.API_HOST}/rest/saveCinAndCpModule`,
}

export const NCCReportApi = {
  getEyfsData: ({pupilId, dateOfMeeting, schoolId, userId, roleId}) => `${settings.API_HOST}/rest/fetchNccEyfsData?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveEyfsData: `${settings.API_HOST}/rest/saveNccEyfsData`,
  
  getSecondaryData: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccSecondaryData?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveSecondaryData: `${settings.API_HOST}/rest/saveNccSecondaryData`,
  
  getPrimaryData: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccPrimaryModule?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  savePrimaryData: `${settings.API_HOST}/rest/saveNccPrimaryData`,

  savePost16Data: `${settings.API_HOST}/rest/saveNccPost16Data`,
  
  getPost16Data: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccPost16Module?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  getPupilVoice: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccPupilVoiceModule?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  savePupilVoice: `${settings.API_HOST}/rest/saveNccPupilVoiceData`,
  
  getPupilVoicePost16: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNCCPost16PupilVoiceData?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  savePupilVoicePost16: `${settings.API_HOST}/rest/saveNccPost16PupilVoiceData`,
  
  getStrengthAndNeedReport: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccStrenghtsAndNeedModule?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveStrengthAndNeedReport: `${settings.API_HOST}/rest/saveNccStrengthAndNeedData`,
  
  getStrengthAndNeedPost16Report: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNCCPost16StrengthAndNeedData?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveNccPost16StrengthAndNeedData : `${settings.API_HOST}/rest/saveNccPost16StrengthAndNeedData `,
  
  getNccMeeting: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNccMeetingModule?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveNccMeeting: `${settings.API_HOST}/rest/saveNccMeetingData`,

  getNccMeetingPost16: ({ pupilId, dateOfMeeting, schoolId, userId, roleId }) => `${settings.API_HOST}/rest/fetchNCCPost16MeetingData?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}`,

  saveNccMeetingPost16: `${settings.API_HOST}/rest/saveNccPost16MeetingData`,

  saveSchoolReport: `${settings.API_HOST}/rest/uplaodSchoolReportFile`,

  exportPdfReports: ({ reportName, pupilId, schoolId, classYear, termofpep, pupilsName, dateofmeeting, pupilSen}) => `${settings.API_HOST}/rest/PDFReports?reportName=${reportName}&pupilId=${pupilId}&schoolId=${schoolId}&classYear=${classYear}&termofpep=${termofpep}&pupilsName=${pupilsName}&dateofmeeting=${dateofmeeting}&pupilSen=${pupilSen}`,

  deleteUploadedFileApi: ({ blobKey }) => `${settings.API_HOST}/rest/deleteSchoolReports?blobkey=${blobKey}`, 

  getNccTargetReport: (activePupilId, dateOfMeeting, schoolId, userId, roleId, pupilsSen="None") => `${settings.API_HOST}/rest/fetchNCCTargetData?pupilId=${activePupilId}&dateOfMeeting=${dateOfMeeting}&schoolId=${schoolId}&userId=${userId}&roleId=${roleId}&pupilsSen=${pupilsSen}`,

  postNccTarget: `${settings.API_HOST}/rest/saveNccTargetData`,

  exportPepStatusExcel: ({ userId, termofPep,pepStatus }) => `${settings.API_HOST}/rest/ExportPepStatus?pepstatus.userid=${userId}&pepstatus.termofPep=${termofPep}&pepstatus.pepStatus=${pepStatus}`,

  exportFilterReportExcel :  ({ userId, pepHeaderStatus }) => `${settings.API_HOST}/rest/StatusExcelExport?userId=${userId}&status=${pepHeaderStatus}`,
  getIsCompleteStatusForAllReport: ({ pupilId, dateOfMeeting }) => `${settings.API_HOST}/rest/completedstatus?pupilId=${pupilId}&dateOfMeeting=${dateOfMeeting}`
}
