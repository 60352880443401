import React, { useContext, useState } from 'react';
import Modal from '../../../../Components/Modal';
import {Formik, Field, Form, useFormik, ErrorMessage} from "formik";
import { pupilVoiceApi } from '../../../../constants/apiUrls';
import PupilContext from '../../../../Components/context/PupilContext';
import axios from 'axios';
import { toast } from 'react-toastify';


// Form Validation method
const formValidation = (values) => {
  const errors = {};
  if(!values.reportName || values.reportName.trim() <= 0) {
    errors.reportName = "Field is required";
  }
  if(!values.uploadedFile || !values.uploadedFile.name) {
    errors.uploadedFile = "Field is required"
  } else if(values.uploadedFile.size <= 0) {
    errors.uploadedFile = "File is empty please select the file which is not empty"
  }
  return errors;
}



const UploadModal = ({ 
  isOpen, 
  closeModal, 
  isSecondary, 
  listHandler, 
  uploadUrl, 
  responseObjectName,
  form
}) => {

  const { activePupilId } = useContext(PupilContext);
  const userId = localStorage.getItem("userId");
  const schoolId = localStorage.getItem("schoolId");

  // Local state 
  const [isLoading, setIsLoading] = useState(false);

  // Form Submission Method
  const formSubmision = (values) => {
    setIsLoading(true);
    const url = uploadUrl;
    const formData = new FormData();
    formData.append("videoFile", values.uploadedFile);
    formData.append("PupilId", activePupilId);
    formData.append("userId", userId);
    formData.append("schoolId", schoolId);
    formData.append("isSecondary", isSecondary);
    formData.append("videoFileName", values.uploadedFile.name);
    formData.append("docType", values.documentType);
    formData.append("docTerm", values.termOfPep);
    formData.append("operation", "");
    formData.append("reportName", values.reportName);

    axios.post(url, formData)
      .then(res => {
        if (responseObjectName) {
          listHandler(res.data[responseObjectName], form)
        } else {
          listHandler(res.data.pepVoiceVideo);
        }
        toast.success("Pupil Voice Document uploaded Successfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
        });
        setIsLoading(false);
        closeModal();
      })
  }


  const initialFormValues = {
    reportName: "",
    documentType: "",
    termOfPep: "",
    uploadedFile: {}
  }

  

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <Formik
        initialValues= {initialFormValues}
        validate={formValidation}
        onSubmit= {formSubmision}
      >
        <Form>
          <div className="upload-modal-voice bg-white p-10 dark-font">
            <div className="upload-modal-header flex justify-between items-center font-bold">
              <h1 className="text-xl">Upload File</h1>
              <i class="fa-regular fa-xmark text-lg modal-cross cursor-pointer" onClick={closeModal}></i>
            </div>

            <div className="upload-modal-voice-form pt-10 flex flex-col gap-5">
              
              <div className="form-row flex gap-10">
                <label htmlFor="" className="font-medium w-52">Report Name</label>
                <div className="w-full flex flex-col justify-start">
                  <Field name="reportName">
                  {
                    (props) => {
                      const {field, form, meta} = props;
                      return (
                        <input
                          type="text"
                          placeholder="Report Name"
                          {...field}
                          className={(form.touched.reportName && form.errors.reportName) ? "rounded-custom-content pep-form-field text-xs w-full pep-form-field-error" : "rounded-custom-content pep-form-field text-xs w-full"}
                        />
                      )
                    }
                  }
                  </Field>
                  <ErrorMessage name="reportName" component="span" className="text-xs pl-1 pt-1 text-red-500" />
                </div>
              </div>

              <div className="form-row flex gap-10 items-center">
                <label htmlFor="" className="font-medium w-52">Document Type</label>
                <div className="w-full flex flex-col justify-start">
                  <Field name="documentType">
                    {
                      (props) => {
                        const { field } = props;
                        return (
                          <input
                            type="text"
                            placeholder="Document Type"
                            className={"rounded-custom-content pep-form-field text-xs w-full"}
                            {...field}
                          />
                        )
                      }
                    }
                  </Field>
                  <ErrorMessage name="documentType" component="span" className="text-xs pl-1 pt-1 text-red-500" />
                </div>
              </div>

              <div className="form-row flex gap-10 items-center">
                <label htmlFor="" className="font-medium w-52">Term of PEP</label>
                <div className="w-full flex flex-col justify-start">
                  <Field name="termOfPep">
                    {
                      (props) => {
                        const { field, form, meta } = props;
                        return (
                          <select 
                            {...field}
                            className="rounded-custom-content pep-form-field text-xs w-full"
                          >
                            <option value="">Select</option>
                            <option value="Autumn 2019">Autumn 2019</option>
                            <option value="Spring 2020">Spring 2020</option>
                            <option value="Summer 2020">Summer 2020</option>
                            <option value="Autumn 2020">Autumn 2020</option>
                            <option value="Spring 2021">Spring 2021</option>
                            <option value="Summer 2021">Summer 2021</option>
                            <option value="Autumn 2021">Autumn 2021</option>
                            <option value="Spring 2022">Spring 2022</option>
                            <option value="Summer 2022">Summer 2022</option>
                          </select>
                        )
                      }
                    }
                  </Field>
                </div>
              </div>

              <div className="form-row flex gap-10 items-center">
                <label htmlFor="" className="font-medium w-52">Select a file</label>
                
                <div className="w-full flex flex-col justify-start">
                  <Field name="uploadedFile">
                    {
                      (props) => {
                        const { field, form, meta } = props;
                        return (
                          <>
                            <label
                              className={(form.touched.uploadedFile && form.errors.uploadedFile) ? "pep-form-field-error rounded-custom-content pep-form-field text-xs w-full  cursor-pointer text-center" : "pep-form-field-error rounded-custom-content pep-form-field text-xs w-full border-blue cursor-pointer text-center"}
                              htmlFor="uploadVoiceFile">
                              {!form.values.uploadedFile.name ? "Browse File" : form.values.uploadedFile.name}
                            </label>
                            <input
                              type="file"
                              className="rounded-custom-content pep-form-field text-xs w-full hidden"
                              id="uploadVoiceFile"
                              // {...field}
                              onChange={(e) => form.setFieldValue("uploadedFile", e.target.files[0])}
                            />
                          </>
                        )
                      }
                    }
                  </Field>
                  <ErrorMessage name="uploadedFile" component="span" className="text-xs pl-1 pt-1 text-red-500" />
                  

                </div>

              </div>

            </div>

            <div className="flex justify-center gap-5 pt-10">
              <button type="button" className="button gray-button" onClick={closeModal}>Cancel</button>
              <button 
                type="submit" 
                className="button purple-button"
                disabled={isLoading}
              >
                {isLoading ? <div className="spinner"></div> : "Save"}
              </button>
            </div>
          </div>
        </Form>
      </Formik>

    </Modal>
  )
}

export default UploadModal;
