import axios from 'axios';
import moment from 'moment';
import React from 'react'
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { NCCReportApi, pupilVoiceApi } from '../../constants/apiUrls';
import PupilContext from '../context/PupilContext';
import { routeUrl } from '../utils/RouteUrl';
import { getPrototypeName, getYearGroup, showSuccessToast, showWarningToast } from '../utils/utilityFunctions';

const NccDataHoc = (OriginalComponent) => {
  
  const WrappedComponent = () => {
    
    const history = useHistory();

    const { pupilInfo } = useContext(PupilContext);

    const attendanceButtonHandler = () => {
      history.push(routeUrl.basic_info_attendance);
    }

    const exclusionsButtonHandler = () => {
      history.push(routeUrl.basic_info_exclusions);
    }

    const saveForm = ({ setIsLoading, values, saveCallback = () => {} }) => {
      setIsLoading(true);
      const postUrl = NCCReportApi.saveEyfsData;
      const formData = new FormData();
      let objectName = "";
      if (getYearGroup(pupilInfo.classYear) === "eyfs") { objectName = "dataEyfs" }
      if (getYearGroup(pupilInfo.classYear) === "primary") { objectName = "dataPrimary" }
      if (getYearGroup(pupilInfo.classYear) === "secondary") { objectName = "dataSecondary" }
      if (getYearGroup(pupilInfo.classYear) === "post16") { objectName = "dataPost16" }

      Object.entries(values[objectName]).forEach(([key, value]) => {
        // if(value && value !== null && typeof value === "object") {
        const valueType = getPrototypeName(value); 
        if (valueType === "Object") {
          Object.entries(value).forEach(([key2, value2]) => {
            formData.append(`dataEyfs.${key}.${key2}`, value2 ? value2 : "");
          })
        } else if (valueType !== "Object" && valueType !== "Array") {
          formData.append(`dataEyfs.${key}`, value ? value : "")
        }
        console.log(typeof value, key);
      })


      axios.post(postUrl, formData)
        .then(res => {
          console.log(res.data, "saving response form eyfs data");
          setIsLoading(false);
          saveCallback(res.data);
        })
        .catch(err => {
          setIsLoading(false);
          showWarningToast("Problem while saving");
        }) 
          
      // setTimeout(() => {
      //   setIsLoading(false);
      // }, 1000)
    }

    const onChangeHandler = ({
      fieldName,
      fieldValue,
      setIsLoading,
      saveForm,
      setFieldValue,
      values
    }) => {
      setFieldValue(fieldName, fieldValue);
      let newValues = {...values}
      const objectFields = fieldName.split(".");
      if(objectFields.length  === 2) {
        newValues[objectFields[0]][objectFields[1]] = fieldValue;
        
      } else if (objectFields.length === 3) {
        if (objectFields[2] === "youngPersonHaveSEND") {
          if(fieldValue === "No") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.SENSupport`, "")
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.EHCP`, "")
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.youngPersonHaveSEND`, fieldValue)
            newValues[objectFields[0]][objectFields[1]][objectFields[2]] = fieldValue;
            newValues[objectFields[0]][objectFields[1]]["SENSupport"] = "";
            newValues[objectFields[0]][objectFields[1]]["EHCP"] = "";
            
          } else if(fieldValue === "Yes") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.youngPersonHaveSEND`, fieldValue)
            newValues[objectFields[0]][objectFields[1]][objectFields[2]] = fieldValue;
          }
        } else {
          newValues[objectFields[0]][objectFields[1]][objectFields[2]] = fieldValue; 
        }

        if (objectFields[2] === "SENSupport") {
          if (fieldValue === "Yes") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.EHCP`, "No");
            newValues[objectFields[0]][objectFields[1]]["EHCP"] = "No";
          } else if(fieldValue === "No") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.EHCP`, "Yes");
            newValues[objectFields[0]][objectFields[1]]["EHCP"] = "Yes";
          }
        }

        if (objectFields[2] === "EHCP") {
          if (fieldValue === "Yes") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.SENSupport`, "No");
            newValues[objectFields[0]][objectFields[1]]["SENSupport"] = "No";
          } else if (fieldValue === "No") {
            setFieldValue(`${objectFields[0]}.${objectFields[1]}.SENSupport`, "Yes");
            newValues[objectFields[0]][objectFields[1]]["SENSupport"] = "Yes";
          }
        }
        
      }
      saveForm({ setIsLoading, "values": newValues });
    }
     
    const numberOnChangeHandler = ({
      setFieldValue,
      fieldValue,
      saveForm,
      setIsLoading,
      maxDigit=false,
      fieldName,
      values,
    }) => {
      const onlyNumberCheckRegex = /^[-+]?[0-9]+$/;
      if (maxDigit) {
        if ((onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") && (fieldValue <= maxDigit)) {
          setFieldValue(fieldName, fieldValue);
          let newValues = { ...values }
          const objectFields = fieldName.split(".");
          if (objectFields.length === 2) {
            newValues[objectFields[0]][objectFields[1]] = fieldValue;
            
          } else if (objectFields.length === 3) {
            newValues[objectFields[0]][objectFields[1]][objectFields[2]] = fieldValue;
              
          }
          saveForm({ setIsLoading, "values": newValues });
        } 
      } 
      else {
        if (onlyNumberCheckRegex.test(fieldValue) || fieldValue === "") {
          setFieldValue(fieldName, fieldValue);
          let newValues = { ...values }
          const objectFields = fieldName.split(".");
          if (objectFields.length === 2) {
            newValues[objectFields[0]][objectFields[1]] = fieldValue;

          } else if (objectFields.length === 3) {
            newValues[objectFields[0]][objectFields[1]][objectFields[2]] = fieldValue;

          }
          saveForm({ setIsLoading, "values": newValues });
        }
      }
    }  

    const dateChangeHandler = ({ date, form, fieldName, setIsLoading, saveForm, setFieldValue, fieldValue }) => {
      // const dateToSave = fieldValue ? moment(fieldValue).format("DD/MM/YYYY") : null;
      setFieldValue(fieldName, fieldValue);
      const values = form.values;
      const splittedFieldName = fieldName.split(".");
     
      switch (splittedFieldName.length) {
        case 1:
          values[splittedFieldName[0]] = fieldValue;
          break;
        case 2:
          values[splittedFieldName[0]][splittedFieldName[1]] = fieldValue;
          break;
        case 3:
          values[splittedFieldName[0]][splittedFieldName[1]][splittedFieldName[2]] = fieldValue;
          break;
      }
      
      saveForm({
        setIsLoading,
        values
      })

    }

    const downloadUploadedFile = ({ blobKey, fileName }) => {
      const a = document.createElement("a");
      const url = pupilVoiceApi.downlaodUrl(blobKey, fileName);
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
    }

    const deleteUploadedFile = ({blobKey, fileName, deleteFile}) => {
      const deleteUrl = NCCReportApi.deleteUploadedFileApi({blobKey})
      Swal.fire({
        title: 'Are you sure',
        text: `you want to delete ${fileName}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: "No",
        confirmButtonText: 'Yes'
      }).then(async (result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {

          axios.get(deleteUrl)
            .then(res => {
              showSuccessToast("File Succussfully deleted")
              deleteFile();
              // console.log(res.data, "delete response from data eyfs upload file");
            })
            .catch(err => {
              showWarningToast("There is problem in deleting file")
            });
        }
      })
      // axios.get(deleteUrl)
      //   .then(res => {
      //     console.log(res.data, "delete response from data eyfs upload file");
      //   })
      //   .catch(err => {
      //     console.log(err);
      //   });
    }

    const exportPdf = (reportName) => {
      const a = document.createElement("a");
      const url = NCCReportApi.exportPdfReports({
        reportName,
        pupilId: pupilInfo.pupilId,
        schoolId: pupilInfo.schoolId,
        classYear: pupilInfo.classYear,
        termofpep: pupilInfo.termOfPep,
        pupilsName: pupilInfo.fullname,
        dateofmeeting: pupilInfo.dateofmeeting,
        pupilSen: "",
      });
      axios.get(url, { responseType: "blob" })
        .then((res) => {
          let objectUrl = window.URL.createObjectURL(res.data);
          a.href = objectUrl;
          a.download = reportName;
          a.click();
          window.URL.revokeObjectURL(objectUrl);
          a.remove();
        })
        .catch(err => {
          const error = err;
          console.log(err);
          console.log(err);
        })
    }

    return (
      <OriginalComponent 
        attendanceButtonHandler={attendanceButtonHandler}
        exclusionsButtonHandler={exclusionsButtonHandler}
        saveForm={saveForm}
        onChangeHandler={onChangeHandler}
        numberOnChangeHandler={numberOnChangeHandler}
        dateChangeHandler={dateChangeHandler}
        downloadUploadedFile={downloadUploadedFile}
        deleteUploadedFile={deleteUploadedFile}
        exportPdf={exportPdf}
      />
    )
  }

  return WrappedComponent;
}

export default NccDataHoc;
