import React, { useEffect, useState } from "react";
import { FiChevronsLeft, FiChevronsRight } from "react-icons/fi";
import { Button } from "antd";

const Pagination = ({
  resultsPerPage,
  totalRecords,
  paginate,
  currentPage,

  totalNumberOfPages,
  hasNextOrPreviousButton,
  showEntries,
}) => {
  // list of pages for paginator
  const pageNumberList = [];

  // How many number to show in paginator row if its indexed
  const totalIndexNumberOfPagination = 5;

  const totalPages = totalNumberOfPages
    ? totalNumberOfPages
    : Math.ceil(totalRecords / resultsPerPage);
  const [indexStart, setIndexStart] = useState(0);
  const [indexEnd, setIndexEnd] = useState(totalIndexNumberOfPagination);
  const [currentEntry, setCurrentEntry] = useState(1);
  const [lastEntry, setLastEntry] = useState(10);
  //const [currentPage, setCurrentPage] = useState(1);

  const handleEntries = (num = currentPage) => {
    if (showEntries) {
      const currentEntryNum = num - 1;
      setCurrentEntry(
        currentEntryNum > 0 ? currentEntryNum * resultsPerPage : num
      );
      setLastEntry(
        num * resultsPerPage <= totalRecords
          ? num * resultsPerPage
          : totalRecords
      );
    }
  };

  useEffect(() => {
    if (currentPage === 1) {
      handleEntries();
      setIndexStart(0);
      setIndexEnd(totalIndexNumberOfPagination);
    }
  }, [resultsPerPage, totalRecords, currentPage]);

  const paginateHandler = (num) => {
    handleEntries(num);
    paginate(num);
  };

  const nextButtonHandler = () => {
    setIndexStart(indexStart + 5);
    setIndexEnd(indexEnd + 5);
  };

  const prevButtonHandler = () => {
    setIndexStart((num) => {
      return num <= 1 ? num : num - totalIndexNumberOfPagination;
    });
    setIndexEnd((num) => {
      return num <= totalIndexNumberOfPagination
        ? num
        : num - totalIndexNumberOfPagination;
    });
  };

  for (let i = 1; i <= totalPages; i++) {
    pageNumberList.push(i);
  }

  const filterFunction = hasNextOrPreviousButton
    ? (num) => num >= indexStart && num <= indexEnd
    : (num) => num === num;

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      handleEntries(newPage);
      paginate(newPage);
    }
  };

  return (
    <div className="flex justify-between items-center pt-3">
      {showEntries && (
        <div>
          <span>
            Showing {currentEntry} to {lastEntry} of {totalRecords} entries
          </span>
        </div>
      )}
      <div className="pagination_container flex justify-start xl:justify-end">
        <ul className="pagination_button flex flex-row items-center justify-center flex-wrap assetpagi">
          <li onClick={prevButtonHandler} className="pr-3">
            <button className="pagiButton left">
              <FiChevronsLeft />
            </button>
          </li>
          {/* { 
            pageNumberList
              .filter(filterFunction)
              .map(num => (
                <li key={num}>
                  <button
                    onClick={() => paginateHandler(num)}
                    className={num === currentPage ? "active" : ""}
                  >
                    {num}
                  </button>

                </li>
                )) 
          }     */}

          <Button
            onClick={() => handlePageChange(currentPage - 1)}
            className="previous-button"
            style={{ background: "#ece9e9", fontWeight: "600",marginLeft:'10px' }}
          >
            Previous
          </Button>

          {pageNumberList.length !== 0 &&
            pageNumberList !== null &&
            pageNumberList.slice(indexStart, indexEnd).map((data, i) => (
              //console.log(activePageNumber, data),
              <li>
                <button
                  className={currentPage === data ? "active" : ""}
                  onClick={(e) => paginateHandler(data)}
                >
                  {data}
                </button>
              </li>
            ))}

          <Button
            onClick={() => handlePageChange(currentPage + 1)}
            className="next-button "
            style={{ background: "#ece9e9", fontWeight: "600" }}
          >
            Next
          </Button>

          <li onClick={nextButtonHandler} className="pl-3">
            <button className="pagiButton right">
              <FiChevronsRight />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Pagination;
